import React from "react";
import { Modal } from "react-bootstrap";
import { setShowHelpSection } from "../../slices/helpContentSlice";
import { useDispatch } from "react-redux";

function UserGuide({ showUserGuide, closeHelp, handleBack, userGuideVideo = {} }) {
    const dispatch = useDispatch();

    // Handle modal close
    const handleClose = () => {
        closeHelp();
        dispatch(setShowHelpSection(false));
    };

    const { Name = "User Guide", Content = [] } = userGuideVideo;
    const videoContent = Content[0] || {};

    return (
        <Modal
            show={showUserGuide}
            dialogClassName="help-section overflow-hidden"
            size="xl"
            backdrop="true"
            onHide={handleClose}
            backdropClassName="helpContent-backdrop"
        >
            <div className="help-section-container">
                <Modal.Header style={{ justifyContent: "space-between" }}>
                    <div
                        className="help-buttons align-items-center"
                        style={{ fontWeight: 700 }}
                        onClick={handleBack}
                    >
                        <i className="fa-solid fa-angle-left"></i>
                        Back
                    </div>
                    <div
                        className="help-buttons align-items-center"
                        onClick={handleClose}
                    >
                        <i className="fa-solid fa-xmark"></i>
                        Close
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="user-guide-container">
                        <div className="header pb-3 pt-20p">{Name}</div>
                        <div className="d-flex justify-content-start pb-20p" style={{ width: 604 }}>
                            {videoContent.Description ? (
                                <iframe
                                    className="user-guide"
                                    src={videoContent.Description}
                                    width="640"
                                    height="360"
                                    allow="fullscreen; picture-in-picture"
                                    title="User Guide Video"
                                ></iframe>
                            ) : (
                                <p>Video content not available.</p>
                            )}
                        </div>
                        <div className="diagnostic-paragraph">
                            {videoContent.Header || "Description not available."}
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default UserGuide;
