import React, { useCallback, useEffect, useState } from "react";
import mainLogo from "../assets/images/unum-Insights-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "./SubHeader";
import Sidebar from "./sidebar/Sidebar";
import { logout } from "./../slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import OrganizationLogo from "./OrganizationLogo";
import { displayLogo } from "../constants/index";
import exportIcon from "../assets/icons/export-icon.svg";
import GTM from "../GTM";
import { useFlags } from "launchdarkly-react-client-sdk";
import InfomationBanner from "./InfomationBanner";
import warning from "../assets/icons/warning-icon.svg";
import InformationBanner from "../assets/icons/displayInformationBanner-icon.svg";
import errorBanner from "../assets/icons/error-banner-icon.svg";
import WarningPage from "../views/preferences/WarningPage";
import { useEventContext } from "../EventContext";
import { setPreferenceChanged } from "../slices/preferenceSlice";
import PreferenceSaveNotification from "../views/preferences/PreferenceSaveNotification";
import { setSelectedRelatedOrganizationsForReports } from "../slices/viewReportSlice";
import HelpSection from "../views/help/HelpSection";

function Header({
    showInformationBanner,
    hideInformationBanner,
    showNotification,
    handleClosePreference,
}) {
    const { user, returnUrl } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [pageSelected, setPageSelected] = useState();
    const [pageGtmTags, setPageGtmTags] = useState();
    const { isPreferenceChanged } = useSelector(
        (state) => state.preferenceSlice
    );
    const showHelpSection = useSelector(
        (state) => state?.helpContent?.showHelpSection
    );
    const { handleEvent } = useEventContext();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        lastSelectedOrganization: organization,
        currentSelectedOrganization,
    } = useSelector((state) => state.organizations);
    const { config } = useSelector((state) => state.app);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openHelp, setOpenHelp] = useState(false);
    const toggleRef = React.useRef(null);

    useEffect(() => {
        if (showHelpSection === true) {
            setOpenHelp(true);
        }
    }, [showHelpSection]);

    const dispatch = useDispatch();
    const gtm = new GTM(null);

    const closeSidebar = useCallback(() => {
        setSidebarOpen(false);
    }, []);

    const closeHelp = useCallback(() => {
        setOpenHelp(false);
    }, []);

    const handleGtmTags = (label) => {
        gtm.Event(
            "top nav",
            "link",
            "click",
            label,
            "top nav",
            "",
            "navigation_click",
            ""
        );
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const handleResize = () => {
        if (window.innerWidth > 769) {
            setSidebarOpen(false);
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const toggleHelp = () => {
        setOpenHelp(true);
    };
    let url = location.pathname;

    let urlDisplay = url.match(displayLogo);

    const {
        displayWarningBanner,
        displayInformationBanner,
        displayErrorbanner,
    } = useFlags();

    const handleInformationBannerClose = () => {
        hideInformationBanner();
    };

    const emitEvent = async () => {
        await handleEvent({ eventType: "ONSAVE_EVENT" });
        dontSave();
    };

    const dontSave = () => {
        setShow(false);
        dispatch(setPreferenceChanged(false));
        navigate(pageSelected);
        handleGtmTags(pageGtmTags);
        dispatch(setSelectedRelatedOrganizationsForReports([]));
    };

    const handleKeyDown = (e, onClick) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onClick();
        }
    };

    const renderInformationBanner = (
        showInformationBanner,
        displayWarningBanner,
        displayInformationBanner,
        displayErrorbanner,
        handleInformationBannerClose
    ) => {
        return (
            <>
                {showInformationBanner && displayWarningBanner && (
                    <InfomationBanner
                        bannerType="warning"
                        bannerImage={warning}
                        bannerText={displayWarningBanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
                {showInformationBanner && displayInformationBanner && (
                    <InfomationBanner
                        bannerType="information"
                        bannerImage={InformationBanner}
                        bannerText={displayInformationBanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
                {showInformationBanner && displayErrorbanner && (
                    <InfomationBanner
                        bannerType="error"
                        bannerImage={errorBanner}
                        bannerText={displayErrorbanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
            </>
        );
    };

    const renderNavigationLinks = (
        url,
        isPreferenceChanged,
        navigate,
        handleGtmTags,
        setPageSelected,
        setPageGtmTags,
        setShow
    ) => {
        const handleBackButtonClick = (item) => {
            setPageSelected(item);
            setPageGtmTags(item.slice(1));
            if (isPreferenceChanged === true) {
                setShow(true);
            } else {
                setShow(false);
            }
        };

        return (
            <>
                <div
                    className={
                        (url.includes("/search") ? "text-bold " : "") + "mr-16p clickable"
                    }
                    data-testid="Search"
                    role="link"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/search");
                            handleGtmTags("search");
                        }
                        handleBackButtonClick("/search");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/search");
                                handleGtmTags("search");
                            }
                            handleBackButtonClick("/search");
                        })
                    }
                >
                    Search
                </div>
                <div
                    className={
                        (url.includes("/reportingcapabilities")
                            ? "text-bold "
                            : "") + "mr-16p clickable"
                    }
                    data-testid="reports"
                    role="link"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/reportingcapabilities");
                            handleGtmTags("reports");
                        }
                        handleBackButtonClick("/reportingcapabilities");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/reportingcapabilities");
                                handleGtmTags("reports");
                            }
                            handleBackButtonClick("/reportingcapabilities");
                        })
                    }
                >
                    Reports
                </div>
                <div
                    className={
                        (url.includes("/preferences") ? "text-bold " : "") +
                        "mr-16p clickable"
                    }
                    role="link"
                    tabIndex={0}
                    onClick={() => {
                        navigate("/preferences");
                        handleGtmTags("preferences");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            navigate("/preferences");
                            handleGtmTags("preferences");
                        })
                    }
                >
                    Preferences
                </div>
                <div
                    className="mr-16p clickable"
                    role="button"
                    tabIndex={0}
                    ref={toggleRef}
                    onClick={() => {
                        toggleHelp();
                    }}
                >
                    Help
                </div>
            </>
        );
    };

    const renderUserSpecificLinks = (
        user,
        returnUrl,
        config,
        handleGtmTags
    ) => {
        return (
            <>
                {returnUrl && returnUrl.includes("my.unum.com") && (
                    <a
                        className="header-text d-flex mr-16p clickable"
                        data-testid="MyUnum"
                        href={config.REACT_APP_MY_UNUM}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleGtmTags("myunum for clients")}
                        aria-label="MyUnum For Clients - Opens in a new tab"
                    >
                        MyUnum For Clients
                    </a>
                )}
                {user?.isUserInternal && (
                    <a
                        className="header-text d-flex mr-16p clickable"
                        data-testid="iservices"
                        href={
                            config.REACT_APP_ISERVICES + ":81/LanAuthentication"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleGtmTags("iservice")}
                        aria-label="iServices - Opens in a new tab"
                    >
                        iServices
                        <img className="ml-4p" src={exportIcon} alt="" />
                    </a>
                )}
                {returnUrl !== null &&
                    !user?.isUserInternal &&
                    !returnUrl?.includes("my.unum.com") && (
                        <a
                            className="header-text d-flex mr-16p clickable"
                            data-testid="iservices"
                            href={
                                config.REACT_APP_ISERVICES +
                                "/_fwWelc/Welcome.aspx"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleGtmTags("iservice")}
                            aria-label="iServices - Opens in a new tab"
                        >
                            iServices
                            <img
                                className="ml-4p"
                                src={exportIcon}
                                alt=""
                            />
                        </a>
                    )}
                <a
                    className="header-text d-flex mr-16p clickable"
                    data-testid="CLI"
                    href={user?.isUserInternal ? config.REACT_APP_CLI_INTERNAL : config.REACT_APP_CLI}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleGtmTags("cli")}
                    aria-label="CLI - Opens in a new tab"
                >
                    CLI
                    <img className="ml-4p" src={exportIcon} alt="" />
                </a>
            </>
        );
    };

    return (
        <>
            <header id="myHeader">
                {renderInformationBanner(
                    showInformationBanner,
                    displayWarningBanner,
                    displayInformationBanner,
                    displayErrorbanner,
                    handleInformationBannerClose
                )}
                <div className="align-items-center container header pl-27p">
                    <div
                        className="sidebar-toggle cursor-pointer"
                        role="button"
                        tabIndex={0}
                        onClick={toggleSidebar}
                        ref={toggleRef}
                        title="Menu"
                        onKeyDown={(e) => handleKeyDown(e, toggleSidebar)}
                    >
                        <i
                            className={
                                "fa-solid text-primary-main " +
                                (sidebarOpen ? "fa-times" : "fa-bars")
                            }
                        ></i>
                    </div>
                    <img src={mainLogo} className="logo" alt="unum insights logo" />
                    <div className="d-flex header-text align-items-center nav-bar">
                        {user && (
                            <>
                                {renderNavigationLinks(
                                    url,
                                    isPreferenceChanged,
                                    navigate,
                                    handleGtmTags,
                                    setPageSelected,
                                    setPageGtmTags,
                                    setShow
                                )}
                                {renderUserSpecificLinks(
                                    user,
                                    returnUrl,
                                    config,
                                    handleGtmTags
                                )}
                            </>
                        )}

                        {urlDisplay !== null && (
                            <div>
                                <OrganizationLogo
                                    organizationName={
                                        currentSelectedOrganization?.ORGNIZATIONNAME
                                    }
                                    card={false}
                                    label={false}
                                    height={30}
                                    width={101.99}
                                    div={false}
                                    style={{ marginRight: 16 }}
                                />
                            </div>
                        )}
                        <button
                            className="signout-button"
                            onClick={() => {
                                dispatch(logout());
                                gtm.Event(
                                    "top nav",
                                    "button",
                                    "click",
                                    "sign out",
                                    "top nav",
                                    "",
                                    "sign_out",
                                    ""
                                );

                                localStorage.clear();
                                sessionStorage.clear();
                            }}
                        >
                            Sign out
                        </button>
                    </div>
                </div>
                {url !== "/home" && <SubHeader />}
                {organization && (
                    <span className="d-none" id="employer-name">
                        {organization?.ORGNIZATIONNAME}
                    </span>
                )}
                {user && (
                    <span className="d-none" id="is-internal-user">
                        {user?.isUserInternal === true ? "Yes" : "No"}
                    </span>
                )}
                {user && (
                    <span className="userid" hidden>
                        {user?.userId}
                    </span>
                )}
                {user && (
                    <span className="user-email" hidden>
                        {user?.userEmail}
                    </span>
                )}
                {user && (
                    <span className="username" hidden>
                        {user?.userName}
                    </span>
                )}
            </header>
            {showNotification && (
                <PreferenceSaveNotification
                    handleClosePreference={handleClosePreference}
                />
            )}
            <Sidebar
                open={sidebarOpen}
                closeSidebar={closeSidebar}
                toggleRef={toggleRef}
            />
            {openHelp && (
                <HelpSection showModal={openHelp} closeHelp={closeHelp} />
            )}
            <WarningPage
                onShow={show}
                onSave={emitEvent}
                onDontSave={dontSave}
            />
        </>
    );
}

export default Header;
