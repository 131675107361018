import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UserGuide from "./UserGuide";
import HelpContent from "./HelpContent";
import DefinitionContent from "./DefinitionContent"
import { setShowHelpSection } from "../../slices/helpContentSlice";
import GTM from "../../GTM.js";
import { useParams, useLocation } from "react-router-dom";

function HelpSection({ showModal, closeHelp, props }) {
    const [show, setShow] = useState(showModal);
    const [showUserGuide, setShowUserGuide] = useState(false);
    const [showHelpContent, setShowHelpContent] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [selectedHelpContent, setSelectedHelpContent] = useState(null);
    const [userGuideVideo, setUserGuideVideo] = useState(null);
    const [animationClass, setAnimationClass] = useState("");
    const [showDefinitionContent, setShowDefinitionContent] = useState(false);
    const [selectedDefinitionContent, setSelectedDefinitionContent] = useState(null);
    const dispatch = useDispatch();
    const helpContentSection = useSelector((state) => state?.helpContent?.helpContentSection);
    const frequentlyAskedQuestions = useSelector((state) => state?.helpContent?.frequentlyAskedQuestions);
    const definitionsExplained = useSelector((state) => state?.helpContent?.definitionsExplained);
    const showHelpSection = useSelector((state) => state?.helpContent?.showHelpSection);
    const { lastSelectedOrganization } = useSelector(
        (state) => state.organizations
    );
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    const gtm = new GTM(null);
    let previousUrls = useLocation().pathname;
    let page_referrer = previousUrls[previousUrls.length - 1];
    let page_referrer_title = "";
    const { id } = useParams();

    useEffect(() => {
        if (showModal) {
            setShow(true);
            setAnimationClass("slide-in");
        } else {
            setAnimationClass("slide-out");
            setTimeout(() => setShow(false), 500);
        }
    }, [showModal]);




    useEffect(() => {
        const userName =
            lastSelectedOrganization?.ORGNIZATIONNAME.toLowerCase();
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal === true ? "internal" : "external";

        gtm.PageView(
            "help panel",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL + page_referrer}`,
            page_referrer_title,
            id
        );
    }, []);

    const handleClose = () => {
        setAnimationClass("slide-out");
        setTimeout(() => {
            setShow(false);
            closeHelp();
            dispatch(setShowHelpSection(false));
        }, 500);
    };

    useEffect(() => {
        if (showHelpSection) {
            const selectedContent = helpContentSection.find(
                (section) => section.Name.toLowerCase().includes("video")
            );
            setUserGuideVideo(selectedContent);
            setShow(false);
            setShowUserGuide(true);
            setShowHelpContent(false);
        }
    }, [showHelpSection, helpContentSection]);

    const handleHelpCard = (helpCardName) => {
        const selectedContent = helpContentSection.find(
            (section) => section.Name === helpCardName
        );
        if (helpCardName.toLowerCase().includes("video")) {
            setUserGuideVideo(selectedContent);
            setShowUserGuide(true);
            setShowHelpContent(false);
        } else if (selectedContent) {
            setSelectedHelpContent(selectedContent);
            setShowHelpContent(true);
        }
        gtm.Event(
            "help section",
            "button",
            "click",
            helpCardName,
            "help",
            "help",
            "help_item_click",
            ""
        );
    };

    const handleDefinitionCard = (helpCardName) => {
        const selectedContent = definitionsExplained.find(
            (section) => section.Name === helpCardName
        );
        if (selectedContent) {
            setSelectedDefinitionContent(selectedContent);
            setShowDefinitionContent(true);
        }
        gtm.Event(
            "help section",
            "button",
            "click",
            helpCardName,
            "help",
            "definitions explained",
            "navigation_click",
            ""
        );
    };

    const handleAccordionToggle = useCallback((index, faq) => {
        const isExpanding = index !== activeAccordion;
        setActiveAccordion((prevState) => (index === prevState ? null : index));
        if (isExpanding) {
            gtm.Event(
                "custom_event",
                "accordion",
                "expand",
                faq.Description,
                "help",
                "faqs",
                "faq_click",
                ""
            );
        }
    }, []);

    const handleBack = () => {
        setShowUserGuide(false);
        setShowHelpContent(false);
        setShowDefinitionContent(false);
        dispatch(setShowHelpSection(false));
        setShow(true)
    };

    // Keydown event handler for scrolling
    const handleKeyDown = (event) => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                modalBody.scrollBy({ top: 50, behavior: 'smooth' });
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                modalBody.scrollBy({ top: -50, behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show]);

    return (
        <>
            {showUserGuide && (
                <UserGuide
                    showUserGuide={showUserGuide}
                    closeHelp={closeHelp}
                    handleBack={handleBack}
                    userGuideVideo={userGuideVideo}
                />
            )}
            {showHelpContent && selectedHelpContent && (
                <HelpContent
                    content={selectedHelpContent}
                    showHelpContent={showHelpContent}
                    handleBack={handleBack}
                    closeHelp={closeHelp}
                />
            )}
            {showDefinitionContent && selectedDefinitionContent && (
                <DefinitionContent
                    content={selectedDefinitionContent}
                    showDefinitionContent={showDefinitionContent}
                    handleBack={handleBack}
                    closeHelp={closeHelp}
                />
            )}
            <Modal
                show={show}
                dialogClassName="help-section"
                size="xl"
                backdrop="true"
                onHide={handleClose}
                className={animationClass}
                backdropClassName="helpSection-backdrop"
            >
                <div className="help-section-container">
                    <Modal.Header style={{ justifyContent: "end" }}>
                        <div className="help-buttons align-items-center" onClick={handleClose}>
                            <i className="fa-solid fa-xmark"></i>
                            Close
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ padding: 0, maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}>
                        <div className="d-flex flex-direction">
                            <div>
                                <div className="header pb-3 pt-20p">Help</div>
                                <div className="help-card-container">
                                    {helpContentSection?.slice()
                                        .sort((a, b) => (Number(a.Order) || 0) - (Number(b.Order) || 0))
                                        .map((section, index) => (
                                            <div
                                                key={index}
                                                className="section-card help-card"
                                                onClick={() => handleHelpCard(section.Name)}
                                            >
                                                <div
                                                    className="icons"
                                                    dangerouslySetInnerHTML={{ __html: section.IconHtml }}
                                                    style={{ width: 59, height: 59 }}
                                                />
                                                <div className="card-header">{section?.Name}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div>
                                <div className="header pt-3" style={{ marginTop: 68 }}>
                                    Definitions Explained
                                </div>
                                <div className="help-card-container d-flex flex-direction">
                                    {definitionsExplained?.slice()
                                        .sort((a, b) => (Number(a.Order) || 0) - (Number(b.Order) || 0))
                                        .map((section, index) => (
                                            <div key={index} className="section-card definitionsExplained"
                                                onClick={() => handleDefinitionCard(section.Name)}
                                            >
                                                <div
                                                    className="icons"
                                                    dangerouslySetInnerHTML={{ __html: section.IconHtml }}
                                                    style={{ width: 59, height: 59 }}
                                                />
                                                <div className="card-header">{section?.Name}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div>
                                <div className="header pt-3" style={{ marginTop: 68 }}>
                                    FAQs
                                </div>
                                <div style={{ width: 579 }}>
                                    {frequentlyAskedQuestions?.map((faq, index) => (
                                        <div className="accordion" key={index}>
                                            <div
                                                className="d-flex px-20p faq-subtext cursor-pointer"
                                                onClick={() => handleAccordionToggle(index, faq)}
                                            >
                                                <h2 className="section-heading mb-0p">
                                                    <div className="faq-header">{faq.Header}</div>
                                                </h2>
                                                <i
                                                    className={`fa-solid fa-${activeAccordion === index ? "minus" : "plus"} accordion-icon ml-8p`}
                                                    tabIndex="0"
                                                />
                                            </div>
                                            {activeAccordion === index && (
                                                <div className="faq-subtext-collapse accordion-collapse show">
                                                    <div className="faq-card card-body">
                                                        {typeof faq.Description === "string" ? (
                                                            faq.Description?.split("\\n").map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <ul className="faq-ul">
                                                                {faq.Description?.Points?.map((point, pointIndex) => (
                                                                    <li key={pointIndex} className="faq-list">
                                                                        {point}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <hr className="mb-20p mx-20p faq-divider" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}

export default HelpSection;
