import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";
import { getButtonDisabledStatus } from "../../utils/helpers";

export default function ReportEditPage({
    onShow,
    onSave,
    onSaveAs,
    onDontSave,
    notification,
    reportDetails,
}) {
    const firstInteractiveElementRef = useRef(null);
    const modalContentRef = useRef(null); // Attach ref to a DOM element wrapping the modal content
    const gtm = new GTM(null);
    const [viewNameChange, setViewNameChange] = useState(false);
    const [descriptionChange, setDescriptionChange] = useState(false);
    const [viewNameValue, setViewNameValue] = useState(reportDetails.Name);
    const [descriptionValue, setDescriptionValue] = useState(
        reportDetails.Description
    );
    const [viewId, setViewId] = useState(reportDetails.ViewId);


    const handleGtmTags = (label) => {
        gtm.Event(
            "custom_event",
            "button",
            "click",
            label,
            "report detail",
            reportDetails.DataViewType.toLowerCase(),
            "field_interaction",
            ""
        );
    };

    useEffect(() => {
        setViewNameValue(reportDetails.Name);
        setDescriptionValue(reportDetails.Description);
        setViewId(reportDetails.ViewId);
    }, [reportDetails]);

    const sendUpdateEvent = () => {
        onSave(viewNameValue, descriptionValue, viewId);
        notification("UPDATE");
        handleGtmTags("update");
    };
    const sendDontSaveEvent = () => {
        setViewNameChange(false);
        setDescriptionChange(false);
        onDontSave();
    };
    const sendSaveAsNewEvent = () => {
        onSaveAs(viewNameValue, descriptionValue, viewId);
        notification("SAVE AS");
        handleGtmTags("save as new");
    };

    const handleViewNameChange = ({ target: input }) => {
        if (input.value.length) {
            setViewNameChange(true);
        } else {
            setViewNameChange(false);
        }
        setViewNameValue(input.value);
    };

    const handleDescriptionValueChange = ({ target: input }) => {
        if (input.value.length) {
            setDescriptionChange(true);
        } else {
            setDescriptionChange(false);
        }
        setDescriptionValue(input.value);
    };


    useEffect(() => {
        if (onShow && firstInteractiveElementRef.current) {
            firstInteractiveElementRef.current.focus();
        }
    }, [onShow]);
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            sendDontSaveEvent(); // Close the dialog on Escape key
        } else if (event.key === "Tab") {

            const focusableElements = modalContentRef.current.querySelectorAll(
                "button, input,i"
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        }
    };
    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
                role="update report popup"
                id="update report Dialog"
                aria-label="update report"
                onKeyDown={(e) => handleKeyDown(e)}
            >
                <div ref={modalContentRef}>
                    <Modal.Header>
                        <SectionHeading heading="Update current report?" />
                        <i
                            className="fa-solid fa-circle-xmark cursor-pointer"
                            onClick={sendDontSaveEvent}
                            aria-label="Close"
                            tabIndex={0}
                        ></i>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <label>Report name</label>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Add a name"}
                                value={viewNameValue}
                                onChange={handleViewNameChange}
                                aria-required="true"
                                autoComplete="off"
                                aria-describedby="reportNameDesc"
                                ref={firstInteractiveElementRef}
                            />
                        </div>
                        <div>
                            <div>
                                <label
                                    style={{
                                        paddingTop: 12,
                                    }}
                                >
                                    Description (Optional)
                                </label>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Add a description"
                                value={descriptionValue}
                                onChange={handleDescriptionValueChange}
                                aria-required="true"
                                autoComplete="off"
                                aria-describedby="reportNameDesc"
                            />
                        </div>
                        <div className="preference-button pt-16p">
                            <div className="d-contents">
                                <Button
                                    data-testid="Save as new"
                                    text="Save as new"
                                    outline={true}
                                    onClick={sendSaveAsNewEvent}
                                    disabled={getButtonDisabledStatus(true, viewNameValue, true, descriptionValue)}
                                    aria-label="Save as new report"
                                />
                                <Button text="Update" onClick={sendUpdateEvent} aria-label="update the report"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </div>

            </Modal>
        </>
    );
}
