import React from "react";
import Table from "../../components/table/HelpTable";

const ColumnDictionaryContent = ({ content }) => {

    const columns = [
        {
            Header: 'Column Name',
            accessor: 'Column Name',
            width: 220
        },
        {
            Header: 'Definition',
            accessor: 'Definition'
        }
    ];


    return (
        <>
            {
                content.length > 0 ? <Table
                    columns={columns}
                    data={content}
                    filterHeader="Column Name"
                    responsiveConfig={{
                        type: "COLUMN",
                        striped: false,
                        screen: "md",
                    }}

                    className="reference-table"
                /> : 
                <div style={{  width:"610px"}}>
                    <div className="d-flex align-items-center justify-content-center" style={{ margin: "0px", height: "50px", padding: "0px 32px" }}>No Data</div>
                </div>
            }

        </>
    );
};

export default ColumnDictionaryContent;