import React, { useState } from "react";

function Row({
    row,
    toggleRowExpanded = null,
    trClassName,
    renderRowSubComponent = null,
    print,
    responsiveConfig,
    displayReportView
}) {
    const responsiveConfigRow =
        responsiveConfig?.type === "ROW" ? "responsive-type-row " : " ";

    const [isFocused, setIsFocused] = useState(false);

    // Handle when the div is focused (via tab)
    const handleFocus = () => {
        setIsFocused(true); // Set aria-selected to true when focused (via tab)
    };

    // Handle when the div loses focus (when user tabs out or clicks elsewhere)
    const handleBlur = () => {
        setIsFocused(false); // Set aria-selected to false when focus is lost
    };

    return (
        <>
            <div
                {...row.getRowProps({
                    onClick:
                        toggleRowExpanded !== null
                            ? () => {
                                toggleRowExpanded(row.id);
                            }
                            : null,
                })}
                className={
                    trClassName +
                    (toggleRowExpanded !== null ? " cursor-pointer " : " ") +
                    (row.index % 2 === 0 ? "even " : "odd ") +
                    (row.index === 0 ? "first " : "") +
                    (responsiveConfig?.type === "COLUMN"
                        ? "responsive-type-column "
                        : responsiveConfigRow)
                }
            >
                {row.cells.map((cell) => {
                    return (
                        <div {...cell.getCellProps()} className="td">
                            {responsiveConfig?.type === "ROW" ? (
                                <div className="row"
                                >
                                    <div
                                        className="col mobile-cell-header"
                                        tabIndex={0}

                                    >
                                        <div>
                                            {cell.column.render("Header")}
                                        </div>
                                    </div>
                                    <div className="col" tabIndex={0}
                                        role="link"
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        aria-selected={isFocused ? true : false}
                                        disabled={!displayReportView ? true : false}
                                        aria-disabled={!displayReportView ? true : false}
                                    >
                                        {cell.render("Cell")}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="mobile-cell-header"
                                        tabIndex={0}
                                    >
                                        {cell.column.render("Header")}
                                    </div>

                                    <div
                                        className="column-cell-content"
                                        tabIndex={0}
                                    >
                                        {cell.render("Cell")}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>

            {(row.isExpanded || print === true) &&
                renderRowSubComponent !== null && (
                    <div role="subrow" className={trClassName}>
                        {renderRowSubComponent({ row })}
                    </div>
                )}
        </>
    );
}

export default Row;
