import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import DefinitionAndClaimComponent from "./DefinitionAndClaimContent";
import ColumnDictionary from "./ColumnDictionaryContent";
import Filter from "./filter";
import filterIcon from "../../assets/icons/filter-icon.svg";

function DefinitionContent({ showDefinitionContent, closeHelp, content, handleBack }) {
    const [show, setShow] = useState(showDefinitionContent);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [filterValue, setFilterValue] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    useEffect(() => {
        setShow(showDefinitionContent);
    }, [showDefinitionContent]);

    const handleClose = () => {
        setShow(false);
        closeHelp();
    };

    // Keydown event handler for scrolling
    const handleKeyDown = (event) => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                modalBody.scrollBy({ top: 1, behavior: 'smooth' });
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                modalBody.scrollBy({ top: -1, behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show]);

    useEffect(() => {

        if (filterValue !== null && filterValue !== '') {
            setFilteredData(
                content?.Content.filter(item =>
                    item["Column Name"]
                        .toLowerCase()
                        .startsWith(filterValue.trim().toLowerCase())  // Match the starting part
                )
            );
        } else {
            setFilteredData(content?.Content);  // If filterValue is empty or null, show all data
        }
    }, [filterValue, content]);

    const closePopup = useCallback(() => {
        setPopupVisible(false)
        if (filterValue === null || filterValue === '') {
            setIsFilterApplied(false)
        } else (
            setIsFilterApplied(true)
        )
    }, [filterValue])

    const handleFilterChange = useCallback((e) => {
        e.preventDefault();
        const newValue = e.target.value;
        setFilterValue(newValue);
    }, []);

    const togglePopup = useCallback(() => {
        setPopupVisible(true);
    }, []);

    const filteredPadding = filteredData?.length > 4 ? "0px 0px 0px 32px" : "0px 28px";
    const contentPadding = content?.Content.length > 0 ? "0px 0px 0px 32px" : "0px 32px"

    return (
        <Modal
            show={show}
            dialogClassName="claim-diagnostic-section"
            size="xl"
            backdrop="true"
            onHide={handleClose}
            backdropClassName="helpContent-backdrop"
        >
            <Modal.Header style={{ justifyContent: "space-between" }}>
                <div
                    className="help-buttons align-items-center"
                    style={{ fontWeight: 700 }}
                    onClick={handleBack}
                >
                    <i className="fa-solid fa-angle-left"></i>
                    Back
                </div>
                <div
                    className="help-buttons align-items-center" style={{ paddingRight: 0 }}
                    onClick={handleClose}
                >
                    <i className="fa-solid fa-xmark"></i>
                    Close
                </div>
            </Modal.Header>
            <div className={`${content.Name === "Column Dictionary" ? "Definitionheader" : "header"}`} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>{content.Name}</div>
                {
                    content.Name === "Column Dictionary" && (
                        <div
                            style={{
                                marginLeft: '2px',
                                cursor: 'pointer',
                                position: "relative",  // Make sure the container is relative for the absolute positioning of the checkmark icon
                            }}

                            tabIndex={0}
                            role="button"
                        >
                            <img
                                onClick={(e) => togglePopup(e)}
                                src={filterIcon}
                                alt="Filter icon"
                                style={{
                                    filter: isPopupVisible ? 'invert(46%) sepia(27%) saturate(450%) hue-rotate(175deg) brightness(94%) contrast(95%)' : 'none',
                                }}
                            />
                            {/* Show the checkmark icon if the filter is applied */}
                            {isFilterApplied && (
                                <i
                                    className="fa-solid fa-circle-check"
                                    style={{
                                        color: "#5E9E42",
                                        position: "absolute",
                                        top: "-6px",
                                        left: "7px",
                                        fontSize: "16px"
                                    }}
                                />
                            )}
                            {isPopupVisible && (
                                <Filter
                                    handleKeyDown={handleKeyDown}
                                    closePopup={closePopup}
                                    filterValue={filterValue}
                                    handleFilterChange={handleFilterChange}
                                />
                            )}
                        </div>
                    )
                }
            </div>

            {content.Name === "Column Dictionary" && <p className="fs" style={{ padding: "0px 32px", marginBottom: "10px" }}>These definations applies to all reports</p>}

            <Modal.Body
                style={{
                    padding: filteredData?.length > 0 ? filteredPadding : contentPadding
                }}
            >
                <div className="modalBodySection" style={{
                    maxHeight: "calc(100vh - 195px)",
                    overflowY: "auto",
                    height: 1000,
                    boxSizing: 'content-box'
                }}>
                    <div className="pr-32p card-section" style={{
                        boxSizing: "border-box",
                    }}>
                        <div style={{ width: "610px" }}>
                            {content.Name === "Column Dictionary" ? <ColumnDictionary content={filteredData} /> : <DefinitionAndClaimComponent content={content} />}
                        </div>
                    </div>
                </div>

            </Modal.Body>

        </Modal>
    );
}

export default DefinitionContent;
