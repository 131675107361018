import React, { useEffect, useRef, useState } from "react";
import Divider from "../../components/ui/Divider";
import useOutsideClick from "../../hooks/useOutsideClick";
import xMarkIcon from "../../assets/icons/X-mark icon.svg";

const PoliciesAndDivisions = ({
    filteredPoliciesAndDivisions,
    selectedPolicyDivision,
    handleSelectAll,
    handleUnselectAll,
    searchTerm,
    handleSearchChange,
    clearSearch,
    handlePolicyCheckboxChange,
    handleDivisionCheckboxChange,
}) => {
    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const isClickedOutside = useOutsideClick(dropdownRef, toggleRef);

    useEffect(() => {
        if (isClickedOutside) {
            setIsOpen(false);
            clearSearch();
        }
    }, [isClickedOutside]);

    useEffect(() => {
        if (isOpen) {
            const focusableElements = dropdownRef.current.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            const handleFocusTrap = (e) => {
                if (e.key === "Tab") {
                    if (e.shiftKey) {
                        if (document.activeElement === firstElement) {
                            e.preventDefault();
                            lastElement.focus();
                        }
                    } else {
                        if (document.activeElement === lastElement) {
                            e.preventDefault();
                            firstElement.focus();
                        }
                    }
                } else if (e.key === "Escape") {
                    setIsOpen(false);
                    clearSearch();
                }
            };

            document.addEventListener("keydown", handleFocusTrap);

            return () => {
                document.removeEventListener("keydown", handleFocusTrap);
            };
        }
    }, [isOpen]);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" || e.key === " " || e.key === "ArrowDown") {
            toggleDropdown();
        } else if (e.key === "Escape") {
            setIsOpen(false);
            clearSearch();
        }
    };

    const formatselectedPolicyDivision = () => {
        return selectedPolicyDivision
            .filter((item) => item.includes("-"))
            .map((item) => {
                const [policyId, divisionId] = item.split("-");
                return divisionId ? `${policyId}-${divisionId}` : null;
            })
            .filter((item) => item !== null);
    };

    const isAllSelected =
        selectedPolicyDivision.length ===
        filteredPoliciesAndDivisions.flatMap((item) => [
            item.policyId,
            ...(Array.isArray(item.divisions)
                ? item.divisions.map(
                    (division) => `${item.policyId}-${division.divisionId}`
                )
                : []),
        ]).length;

    const isAnySelected = selectedPolicyDivision.length > 0;

    const placeholderText =
        selectedPolicyDivision.length === 0 ? (
            <div style={{ color: "#aaa" }}>Add filters +</div>
        ) : isAllSelected ? (
            "All items are selected."
        ) : (
            formatselectedPolicyDivision().toString()
        );

    return (
        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
            <label htmlFor="policy-division-toggle" className="header pb-0">
                Policies and divisions
            </label>
            <div
                id="policy-division-toggle"
                role="combobox"
                aria-expanded={isOpen}
                aria-controls="policy-division-dropdown"
                aria-haspopup="listbox"
                className="d-flex justify-content-between cursor-pointer reportPolicyFilter"
                onClick={toggleDropdown}
                ref={toggleRef}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                aria-label="Policies and Divisions"
            >
                <span id="multi-select-label" className="sr-only">
                    Use the arrow keys to navigate options, Enter to select, and Escape to close.
                </span>
                <div className="policyDivisionDropDown">{placeholderText}</div>
                <div
                    className="d-flex"
                    style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        paddingRight: 8,
                        gap: 8,
                    }}
                >
                    {isOpen ? (
                        <i className="fa-solid fa-angle-up" aria-hidden="true"></i>
                    ) : (
                        <i className="fa-solid fa-angle-down" aria-hidden="true"></i>
                    )}
                </div>
            </div>
            <div
                style={{ position: "absolute" }}
                ref={dropdownRef}
                id="policy-division-dropdown"
                role="region"
                aria-label="Policy and Division Dropdown"
            >
                {isOpen && (
                    <div
                        className="section-card policy-divivsion-dropdown"
                        style={{ left: "-211px" }}
                    >
                        <div className="d-flex fs py-8p" style={{ gap: 8 }}>
                            <div className="pl-16p text-bold">
                                Select Policies and Divisions
                            </div>
                            <div
                                className={`${isAllSelected ? "disabled-text" : "hyper-links"
                                    }`}
                                onClick={handleSelectAll}
                                disabled={isAllSelected}
                                aria-disabled={isAllSelected}
                                tabIndex={isAllSelected ? -1 : 0}
                                aria-live="polite"
                                aria-label={`Select All ${isAllSelected ? 'button is disabled' : 'button is active'}`}
                                role="button"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                        e.preventDefault();
                                        handleSelectAll();
                                    }
                                    if (e.key === "Escape") {
                                        setIsOpen(false);
                                        clearSearch();
                                    }
                                }}
                            >
                                Select All
                            </div>
                            <div
                                className={`${isAnySelected ? "hyper-links" : "disabled-text"
                                    }`}
                                onClick={handleUnselectAll}
                                disabled={!isAnySelected}
                                aria-disabled={!isAnySelected}
                                tabIndex={isAnySelected ? 0 : -1}
                                aria-live="polite"
                                aria-label={`Deselect All ${isAnySelected ? 'button is active' : 'button is disabled'}`}
                                role="button"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                        e.preventDefault();
                                        handleUnselectAll();
                                    }
                                    if (e.key === "Escape") {
                                        setIsOpen(false);
                                        clearSearch();
                                    }
                                }}
                            >
                                Deselect All
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ backgroundColor: "#E6EEF4" }}
                        >
                            <div
                                className="search-containr pl-16p d-flex align-items-center"
                                style={{
                                    paddingTop: "6px",
                                    paddingBottom: "8px",
                                }}
                            >
                                <i
                                    className="fa-solid fa-magnifying-glass icon mx-4p"
                                    aria-hidden="true"
                                ></i>
                                <input
                                    className="dropdown-search no-border-on-focus"
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    aria-label="Search Policies and Divisions"
                                    tabIndex={0}
                                    style={{
                                        border: "none",
                                        backgroundColor: "#E6EEF4",
                                        fontSize: "16px",
                                    }}
                                />
                            </div>
                            <div
                                className="cursor-pointer "
                                style={{ paddingRight: 16 }}
                            >
                                {searchTerm && (
                                    <img
                                        src={xMarkIcon}
                                        onClick={clearSearch}
                                        width="16px"
                                        alt="Clear search input"
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.key === " ") {
                                                toggleDropdown();
                                                e.preventDefault();
                                            }
                                            if (e.key === "Escape") {
                                                setIsOpen(false);
                                                clearSearch();
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className="custom-scrollbar"
                            style={{ maxHeight: 394 }}
                        >
                            {filteredPoliciesAndDivisions.length === 0 ? (
                                <div className="d-flex justify-content-center my-8p text-center">
                                    No options
                                </div>
                            ) : (
                                filteredPoliciesAndDivisions.map(
                                    (item, index) => (
                                        <div
                                            className={`policy-section pl-16p ${selectedPolicyDivision.includes(item.policyId)
                                                ? "selected-checkbox"
                                                : ""
                                                }`}
                                            key={index}
                                            style={{ paddingTop: 8 }}
                                        >
                                            <div style={{ gap: 10 }}>
                                                <input
                                                    type="checkbox"
                                                    id={item.policyId}
                                                    className="custom-checkbox"
                                                    onChange={() =>
                                                        handlePolicyCheckboxChange(item.policyId)
                                                    }
                                                    checked={selectedPolicyDivision.includes(
                                                        item.policyId
                                                    )}
                                                    aria-label={`list-${index + 1} of ${filteredPoliciesAndDivisions.length} with ${item.divisions.length}`}

                                                />
                                                <label id={`label-${item.policyId}`} htmlFor={item.policyId}>
                                                    {item.policyId}
                                                </label>
                                            </div>
                                            {item.divisions.map(
                                                (division, idx) => (
                                                    <ul
                                                        className={`d-flex ml-16n mb-0p ${selectedPolicyDivision.includes(
                                                            `${item.policyId}-${division.divisionId}`
                                                        )
                                                            ? "selected-checkbox"
                                                            : ""
                                                            }`}
                                                        style={{
                                                            paddingTop: 8,
                                                        }}
                                                        key={idx}
                                                    >
                                                        <div>
                                                            <label
                                                                className="d-flex pl-16p"
                                                                style={{
                                                                    gap: 10,
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={`${item.policyId}-${division.divisionId}`}
                                                                    className="custom-selected-checkbox"
                                                                    onChange={() =>
                                                                        handleDivisionCheckboxChange(
                                                                            `${item.policyId}-${division.divisionId}`,
                                                                            item.policyId
                                                                        )
                                                                    }
                                                                    checked={selectedPolicyDivision.includes(
                                                                        `${item.policyId}-${division.divisionId}`
                                                                    )}
                                                                />
                                                                <span id={`label-${item.policyId}-${division.divisionId}`}>
                                                                    {division.divisionId}
                                                                    {division.divisionName
                                                                        ? ` - ${division.divisionName}`
                                                                        : ""}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </ul>
                                                )
                                            )}
                                            {index !==
                                                filteredPoliciesAndDivisions.length -
                                                1 && (
                                                    <Divider
                                                        className="ml-16n mb-0p "
                                                        mx="mt-0p "
                                                    />
                                                )}
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PoliciesAndDivisions;
