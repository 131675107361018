import React, { useState, useEffect } from "react";
import GTM from "../../GTM";

function ModifyViewButton({ table, selectedViewDataType }) {
    const [isDropdownVisible, setDropdownVisibility] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const gtm = new GTM(null);

    const toggleDropdown = () => {
        setDropdownVisibility(!isDropdownVisible);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "modify view",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const closeDropdown = (event) => {
        if (!event.target.closest(".modify-view, .dropdown-content")) {
            setDropdownVisibility(false);
            setSearchQuery("");
            setFocusedIndex(0);
        }
    };

    useEffect(() => {
        window.addEventListener("click", closeDropdown);
        return () => window.removeEventListener("click", closeDropdown);
    }, []);

    useEffect(() => {
        if (isDropdownVisible) {
            const firstFocusableElement = document.querySelector(".dropdown-search");
            if (firstFocusableElement) {
                firstFocusableElement.focus();
            }
        }
    }, [isDropdownVisible]);

    const filteredColumns = table
        .getAllLeafColumns()
        .filter((column) =>
            column.id.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const clearSearch = () => {
        setSearchQuery("");
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();

        if (isDropdownVisible) {
            switch (e.key) {
                case "ArrowDown":
                    setFocusedIndex((prev) =>
                        Math.min(filteredColumns.length, prev + 1)
                    );
                    break;
                case "ArrowUp":
                    setFocusedIndex((prev) => Math.max(0, prev - 1));
                    break;
                case "Enter":
                case " ":
                    if (focusedIndex === 0) {
                        table.getToggleAllColumnsVisibilityHandler()();
                    } else if (focusedIndex > 0) {
                        const column = filteredColumns[focusedIndex - 1];
                        if (column?.getCanHide()) {
                            column.getToggleVisibilityHandler()();
                        }
                    }
                    break;
                case "Escape":
                    setDropdownVisibility(false);
                    break;
                default:
                    break;
            }
        } else if (e.key === "ArrowDown" || e.key === "Enter" || e.key === " ") {
            setDropdownVisibility(true);
            setFocusedIndex(0);
        }
    };

    useEffect(() => {
        if (isDropdownVisible) {
            const focusableItems = document.querySelectorAll(".dropdown-search");
            if (focusableItems[focusedIndex]) {
                focusableItems[focusedIndex].focus();
            }
        }
    }, [focusedIndex, isDropdownVisible]);

    return (
        <div
            className="col-lg-2 col-sm-2 col-2"
            style={{ width: "fit-content" }}
        >
            <div className="dropdown">
                <button
                    className="modify-view"
                    onClick={toggleDropdown}
                    onKeyDown={handleKeyDown}
                    role="combobox"
                    aria-expanded={isDropdownVisible}
                    aria-controls="multi-select-dropdown"
                    aria-label="Columns"
                    tabIndex={0}
                >
                    <span id="modify-columns" className="sr-only">
                        Use the arrow keys to navigate options, Enter to select, and Escape to close.
                    </span>
                    Columns <i className="fa-solid fa-plus-minus"></i>
                </button>
                {isDropdownVisible && (
                    <div
                        id="myDropdown"
                        className={`dropdown-content show`}
                        onClick={(e) => e.stopPropagation()}
                        tabIndex={0}
                        onKeyDown={handleKeyDown}
                        role="listbox"
                        aria-label="Use the arrow keys to navigate options, Enter to select, and Escape to close."
                    >
                        <div className="dropdown-header">Columns displayed</div>
                        <div className="search-container">
                            <i className="fa-solid fa-magnifying-glass icon mx-4p"></i>
                            <input
                                className="dropdown-search"
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                tabIndex={0}
                                aria-label="Search columns"
                            />
                            {searchQuery && (
                                <div
                                    className="icon cursor-pointer"
                                    onClick={clearSearch}
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Clear search"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" || e.key === " ") {
                                            e.preventDefault();
                                            clearSearch();
                                        }
                                    }}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                            )}
                        </div>

                        <div
                            className="custom-scrollbar"
                            style={{
                                maxHeight: 200,
                            }}
                        >
                            {filteredColumns.length === 0 && (
                                <div className="d-flex justify-content-center my-8p text-center">
                                    No options
                                </div>
                            )}

                            <div>
                                {filteredColumns.length > 0 && (
                                    <div>
                                        <label className="dropdown-list">
                                            <input
                                                type="checkbox"
                                                checked={table.getIsAllColumnsVisible()}
                                                onChange={table.getToggleAllColumnsVisibilityHandler()}
                                                tabIndex={0}
                                                aria-label="Select all columns"
                                            />{" "}
                                            Select All
                                        </label>
                                    </div>
                                )}

                                {filteredColumns.map((column, index) => (
                                    <div
                                        key={column.id}
                                        role="option"
                                        aria-selected={focusedIndex === index + 1}
                                        style={{
                                            ...(column.getCanHide()
                                                ? {}
                                                : {
                                                    pointerEvents: "none",
                                                }),
                                            ...(column.getIsVisible()
                                                ? {
                                                    cursor: "pointer",
                                                }
                                                : {}),
                                        }}
                                    >
                                        <label className="dropdown-list">
                                            <input
                                                type="checkbox"
                                                checked={column.getIsVisible()}
                                                onChange={column.getToggleVisibilityHandler()}
                                                style={
                                                    column.getCanHide()
                                                        ? {}
                                                        : {
                                                            backgroundColor: "#AAAAAA",
                                                        }
                                                }
                                                tabIndex={column.getCanHide() ? 0 : -1}
                                                aria-label={`Toggle visibility for ${column.id}`}
                                            />{" "}
                                            {column.id}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ModifyViewButton;
