import React, { useMemo } from 'react';

// Utility to group data by first letter of "Status Reason"
const groupDataByLetter = (data, filterHeader) => {
  return data.reduce((acc, item) => {
    const statusReason = item[filterHeader];
    if (!statusReason) {
      return acc;
    }
    const firstLetter = statusReason?.[0]?.toUpperCase();
    if (firstLetter) {
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(item);
    }
    return acc;
  }, {});
};

const DynamicTable = ({ columns, data, responsiveConfig, filterHeader }) => {
  // Always call the useMemo hooks unconditionally
  const groupedData = useMemo(() => groupDataByLetter(data, filterHeader), [data]);

  // Convert groupedData to an array of rows with their respective subheaders
  const groupedRows = useMemo(
    () =>
      Object.keys(groupedData).map(letter => ({
        letter,
        rows: groupedData[letter],
      })),
    [groupedData]
  );

  // Check if data is empty and return a message early
  if (!Array.isArray(data) || data?.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className={`table-container ${responsiveConfig?.screen}`}>
      <table
        className={`table ${responsiveConfig?.striped ? 'striped' : ''}`}
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.Header}
                colSpan="1"
                style={{ width: column.width || 'auto' ,     color: "#015294",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "28px",
                    padding:"20px 20px 20px",
                    textAlign: "left"}}
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groupedRows.map((group) => (
            <React.Fragment key={group.letter}>
              <tr>
                <td
                  colSpan={columns.length}
                  style={{ textAlign: 'left', fontWeight: 'bold' }}
                >
                  {group.letter}
                </td>
              </tr>
              {group.rows.map((row) => (
                <tr key={`${row.Definition}-${row.Code}`}
                style={{
                    backgroundColor: group.rows.indexOf(row) % 2 === 0 ? '#f6f8fa' : '#ffffff',
                    padding: "8px 20px",
                    fontSize: "16px",
                    lineHeight: "25px",
                    color: "#212529" // Apply alternating colors
                  }}>
                  {columns.map((column) => (
                    <td key={column.accessor} style={{textAlign:"justify"}}>
                      {row[column.accessor] || '-'}
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
