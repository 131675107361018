import React, { useImperativeHandle, forwardRef } from "react";
import * as XLSX from "xlsx"; // Ensure you have the XLSX library installed
import GTM from "../../GTM";
import { absenceType, changesReportType, currencyColumns, disabilityType, leaveType, paymentHistoryType } from "../../constants";

const ExportToCSV = forwardRef(
    (
        {
            data,
            defaultColumns,
            selectedReport,
            reportLastFetched,
            userEmail,
            portalUserApiError,
            reportingPeriodValue,
            reportingStatusValue,
            selectedCoverageType,
            selectedViewDataType,
            includeHistoricalLeaveValue,
            periodScheduleTypeValue,
            periodStatusValue,
            protectionStatusValue,
            disclaimer,
            organizationsAccess,
            columnVisibility,
            columnOrder,
            ssnDisplayMode,
            organizationName,
            relatedOrganizationNames,
            selectedPolicyDivision,
            selectedReportingGroup,
            columnFilters
        },
        ref
    ) => {
        const gtm = new GTM(null)
        const prepareSecondPageData = () => {
            const data = [
                ["Report", selectedReport?.label],
                [],
                ["Date export run", reportLastFetched],
                [],
                ["Organization Name", organizationName],
                [],
                ["Related Organizations", relatedOrganizationNames?.length > 0 ? relatedOrganizationNames : "None"],
                [],
                ["Run by user", userEmail],
                [],
                ["Additional filters / Column filters", "Column filter values"],
            ];

            if (columnFilters?.length > 0) {
                columnFilters.map((filter) => data.push([filter.columnName, filter.filterValue]));
            } else {
                data.push(["None", "None"]);
            }

            data.push([" "]);
            data.push(["Disclaimer", disclaimer]);

            data.push([]);
            data.push(["Filter", "Filter Values"]);

            if (!portalUserApiError) {
                data.push(["Reporting period", reportingPeriodValue.value]);
                const periodRange = reportingPeriodValue.label.split("-", 4);
                data.push(["From date", periodRange[0].slice(-11)]);
                data.push(["To date", reportingPeriodValue.label.slice(-11).trim()]);

                if ([leaveType, absenceType, disabilityType, paymentHistoryType].includes(selectedViewDataType)) {
                    data.push(["Coverage type", selectedCoverageType.map((item) => item.value).join(", ")]);
                }


                if ([leaveType, absenceType, disabilityType, paymentHistoryType].includes(selectedViewDataType)) {
                    data.push([
                        selectedViewDataType === "Payments" ? "Payment status" : "Status",
                        reportingStatusValue.map((item) => item.value).join(", ")
                    ]);
                }
                if ([absenceType, disabilityType, paymentHistoryType].includes(selectedViewDataType)) {
                    const policiesDivisions = selectedPolicyDivision
                        ?.filter((item) => item?.includes("-"))
                        .join(", ");

                    const maxLength = 32767; // Excel cell limit

                    if (policiesDivisions.length > maxLength) {
                        const splitByCommaSpace = policiesDivisions.split(", ");
                        let chunk = "";
                        let chunks = [];

                        splitByCommaSpace.forEach((item) => {
                            if ((chunk.length + item.length + 2) > maxLength) {
                                chunks.push(chunk);
                                chunk = item;
                            } else {
                                chunk += (chunk ? ", " : "") + item;
                            }
                        });
                        if (chunk) chunks.push(chunk);

                        chunks.forEach((chunk, index) => {
                            data.push([
                                index === 0 ? "Policies and Divisions" : "",
                                chunk,
                            ]);
                        });
                    } else {
                        data.push(["Policies and Divisions", policiesDivisions]);
                    }
                }


                if ([leaveType, absenceType, changesReportType].includes(selectedViewDataType)) {
                    data.push([
                        "Reporting Group",
                        Array.isArray(selectedReportingGroup)
                            ? selectedReportingGroup.map((item) => item?.value).join(", ")
                            : ""
                    ]);
                }
                if ([leaveType, absenceType].includes(selectedViewDataType)) {
                    data.push(["Include Historical Leave", includeHistoricalLeaveValue?.label || ""]);
                    data.push([
                        "Period Schedule Type",
                        Array.isArray(periodScheduleTypeValue)
                            ? periodScheduleTypeValue.map((item) => item?.value).join(", ")
                            : ""
                    ]);


                    const periodStatusString = Array.isArray(periodStatusValue)
                        ? periodStatusValue.map((item) => item?.value).join(", ")
                        : "";

                    const protectionStatusString = Array.isArray(protectionStatusValue)
                        ? protectionStatusValue.map((item) => item?.value).join(", ")
                        : "";

                    data.push(["Period Status", periodStatusString]);
                    data.push(["Protection Status", protectionStatusString]);
                }
            }

            return data;
        };

        const filterData = (columnVisibility, columnOrder, ssnDisplayMode) => {
            const keysToInclude = columnOrder.filter((key) => columnVisibility[key]);
            const ssnAccess = organizationsAccess.SSNACCESS;

            const headerRow = keysToInclude.map((key) => {
                const column = defaultColumns.find((col) => col.accessorKey === key);
                return column ? column.id : key;
            }).filter(Boolean);

            const filteredData = data.map((row) =>
                keysToInclude.map((key) => {
                    if (key === "taxIdentificationNumber") {
                        if (ssnAccess === "No Access") return "No Access";
                        switch (ssnDisplayMode) {
                            case 0:
                                return "***-**-****";
                            case 1:
                                return row[key]?.substr(-4) || "";
                            case 2:
                                return row[key] || "";
                            default:
                                return "";
                        }
                    }
                    const value = row[key] || "";
                    return typeof value === "string" && value.includes("$")
                        ? Number(value.replace(/[$,]/g, ""))
                        : value;
                })
            );

            return [headerRow, ...filteredData];
        };

        const exportToExcel = () => {
            const wb = XLSX.utils.book_new();
            const filteredData = filterData(columnVisibility, columnOrder, ssnDisplayMode);

            const ws1 = XLSX.utils.aoa_to_sheet(filteredData);
            ws1["!cols"] = filteredData[0].map(() => ({ wpx: 100 }));
            currencyColumns.forEach((colName) => {
                const colIndex = filteredData[0].indexOf(colName);
                if (colIndex !== -1) {
                    const range = XLSX.utils.decode_range(ws1["!ref"]);
                    for (let row = range.s.r + 1; row <= range.e.r; row++) {
                        const cellRef = XLSX.utils.encode_cell({ r: row, c: colIndex });
                        const cell = ws1[cellRef];
                        if (cell && !isNaN(cell.v)) cell.z = "$#,##0.00";
                    }
                }
            });
            XLSX.utils.book_append_sheet(wb, ws1, "Data");

            const secondPageData = prepareSecondPageData();
            const ws2 = XLSX.utils.aoa_to_sheet(secondPageData);
            ws2["!cols"] = secondPageData[0].map(() => ({ wpx: 100 }));
            XLSX.utils.book_append_sheet(wb, ws2, "Filter criteria");

            XLSX.writeFile(wb, `${selectedReport?.label}.xlsx`);

            gtm.Event(
                "custom_event",
                "button",
                "click",
                "export to csv",
                "report detail",
                selectedViewDataType,
                "export_report",
                ""
            );
        };

        useImperativeHandle(ref, () => ({
            exportToExcel,
        }));

        return null;
    }
);

export default ExportToCSV;
