import React from "react";
import filterIcon from "../../assets/icons/filter-icon.svg";

const Filter = (props) => {
    const { handleKeyDown, closePopup, filterValue, handleFilterChange } = props;
    return (
        <div
            className="filter-popup-box section-card"
            role="dialog"
            aria-labelledby="filter-popup-title"
            aria-describedby="filter-popup-description"
            aria-modal="true"
            onKeyDown={(e) => handleKeyDown(e)}
            style={{ right: "0px" }}
        >
            <div className="top-line">
                <div className="title" id="filter-popup-title">
                    <img
                        src={filterIcon}
                        alt="Filter Icon"
                    />
                    <label style={{ color: "rgb(33, 37, 41)", fontSize: "14px" }}>
                        Filter Column Names
                    </label>
                </div>
                <div
                    className="close-area filterClose"
                    tabIndex={0}
                    role="button"
                    aria-label="Close filter popup"
                    onClick={closePopup}
                >
                    <i className="fa-solid fa-xmark" style={{ lineHeight: "unset", fontSize: "14px" }}></i>
                    <span className="close-text">Close</span>
                </div>
            </div>
            <div className="filter-box" id="filter-popup-description">
                <i className="fa fa-search search" aria-hidden="true" style={{ fontSize: "14px" }}></i>
                <input
                    style={{ fontSize: "14px" }}
                    type="text"
                    value={filterValue}
                    onChange={(e) => handleFilterChange(e)}
                    placeholder={`Start typing...`}
                    className="input-box"
                    autoFocus
                />
            </div>
        </div>
    )
}
export default Filter;