import React, { useEffect, useRef, useState } from "react";
import ReportTable from "./ReportTable";
import ExportToCSV from "./ExportToCSV";

function ReportViewData({
    organizationIDISF,
    selectedReportDetails,
    reportData,
    dataViewType,
    reportViewInformation,
    onViewOptionChange,
    onViewReportSave,
    allViewsData,
    loaderStatus,
    onViewReportUpdate,
    notificationStatus,
    reportTableData,
    isFetching,
    isUninitialized,
    isError,
    handleCoverageType,
    handleViewID,
    reportLastFetched,
    userEmail,
    filterValues,
    initialFiltersValue,
    portalUserApiError,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportingPeriodValue,
    reportingStatusValue,
    selectedCoverageType,
    selectedPolicyDivision,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    periodStatusValue,
    protectionStatusValue,
    disclaimer,
    totalResults,
    isFetchingMore,
    portalUserData,
    totalPages,
    pagecounter,
    isDataFormattingComplete,
    organizationName,
    relatedOrganizationNames,
    selectedReportingGroup,
    isFetchLoading,
    clearColumnFilters,
    setClearColumnFilters,
    page,
    setPage
}) {
    const exportRef = useRef();
    const [pageSize, setPageSize] = useState(20);
    let filteredData = [];
    const [newFileData, setFileData] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([])
    const [formatPageData, setFormatPageData] = useState(false);
    const [selectedReport, setselectedReport] = useState(null);
    const workerRef = useRef(null);

    const [sortType, setSortype] = useState();

    const [columnFilter, setColumnFilter] = useState([]);
    const [clearColumnFilter, setClearColumnFilter] = useState([]);

    const [filteredDataLength, setFiltredDataLength] = useState();
    const [exportData, setExportData] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState([]);
    const [ssnDisplayMode, setSSNDisplayMode] = useState(0);
    const [columnOrder, setColumnOrder] = useState()
    const handleExportData = (columnOrder) => {
        setExportData(true);
        setColumnOrder(columnOrder);
    };

    const handleSelectedReport = (report) => {
        setselectedReport(report)
    }

    const handlecolumnVisibility = (columnVisibility) => {
        setColumnVisibility(columnVisibility)
    };
    const handleSsnDisplayMode = (displayMode) => {
        setSSNDisplayMode(displayMode)
    }
    useEffect(() => {
        if (exportData && exportRef.current) {
            exportRef.current.exportToExcel(); // Call the export function
            setExportData(false); // Reset the state to prevent repeated calls
        }
    }, [exportData]);

    useEffect(() => {
        workerRef.current = new Worker(new URL("./worker.js", import.meta.url));

        workerRef.current.onmessage = function (event) {
            setFilteredArrayData(event.data.filteredData);
            setFileData(event.data.paginatedData);
            setFormatPageData(false);
            setFiltredDataLength(event.data.totalFilteredCount);
        };
        return () => {
            workerRef.current.terminate();
        };
    }, []);

    useEffect(() => {
        if (reportTableData && workerRef.current) {

            workerRef.current.postMessage({
                filterValue: columnFilter,
                page: page,
                pageSize: pageSize,
                array: reportTableData,
                sortType: sortType,
            });
        }
    }, [columnFilter, page, pageSize, reportTableData, sortType]);

    const handlePageSelect = (item) => {
        setPage(item);
        if (workerRef.current) {
            workerRef.current.postMessage({
                filterValue: columnFilter,
                page: page,
                pageSize: pageSize,
                array: reportTableData,
                sortType: sortType,
            });
        }
    };

    const getPageCount = () => {
        return Math.ceil(filteredDataLength / pageSize);
    };
    const getPagePerItems = (items) => {
        setPageSize(items);
    };

    const handleSortingChange = (item) => {
        setFormatPageData(true);
        setSortype(item);
        if (workerRef.current) {
            workerRef.current.postMessage({
                page: page,
                pageSize: pageSize,
                array: reportTableData,
                sortType: item,
                filterValue: columnFilter,
            });
        }
    };

    const handleColumnFilter = (newFilter) => {
        setFormatPageData(true);
        setClearColumnFilters(false)
        setColumnFilter((prevFilters) => {
            // Clear all filters if newFilter is an empty array
            if (Array.isArray(newFilter) && newFilter.length === 0) {
                if (workerRef.current) {
                    workerRef.current.postMessage({
                        filterValue: [], // Clear all filters
                        page: page,
                        pageSize: pageSize,
                        array: reportTableData,
                        sortType: sortType,
                    });
                }
                return []; // Clear all filters
            }

            // Check if the filter for the given columnName already exists
            const existingFilterIndex = prevFilters?.findIndex(
                (filter) => filter?.accessorKey === newFilter?.accessorKey
            );

            let updatedFilters;
            if (existingFilterIndex >= 0) {
                // Replace the existing filter for this columnName
                updatedFilters = [...prevFilters];
                updatedFilters[existingFilterIndex] = newFilter;
            } else {
                // Add the new filter
                updatedFilters = [...prevFilters, newFilter];
            }

            if (workerRef.current) {
                workerRef.current.postMessage({
                    filterValue: updatedFilters, // Pass all filters
                    page: page,
                    pageSize: pageSize,
                    array: reportTableData,
                    sortType: sortType,
                });
            }

            return updatedFilters;
        });
    };

    useEffect(() => {
        if (workerRef.current && (clearColumnFilter.length > 0 || clearColumnFilters === true)) {
            setFormatPageData(true);

            workerRef.current.postMessage({
                filterValue: clearColumnFilter, // Pass all filters
                page: page,
                pageSize: pageSize,
                array: reportTableData,
                sortType: sortType,
            });
            setColumnFilter(clearColumnFilter);
        }
    }, [clearColumnFilter, clearColumnFilters]);

    const sortingDtFn = (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);

        const parseValue = (value) => {
            const dateV = new Date(value);
            return dateV;
        };

        const a = parseValue(valueA);
        const b = parseValue(valueB);

        return a > b ? 1 : a < b ? -1 : 0;
    };
    const columnsData = () => {
        return reportViewInformation[0].ColumnInformation.map((obj, index) => {
            const hasDateKeyword = String(obj.id).toLocaleLowerCase().includes("date");
            const chosenSortFn = hasDateKeyword ? sortingDtFn : null;

            return index !== null ? { ...obj, sortingFn: chosenSortFn } : obj;
        });
    };
    const columnsDatas = reportViewInformation[0].ColumnInformation
    const idToAccessorKeyMap = columnsDatas.reduce((map, column) => {
        map[column.id] = column.accessorKey;
        return map;
    }, {});

    // Replace keys in columnVisibility
    const updatedColumnVisibility = Object.fromEntries(
        Object.entries(columnVisibility).map(([key, value]) => {
            const newKey = idToAccessorKeyMap[key] || key;
            return [newKey, value];
        })
    );

    const updatedColumnOrder =
        Array.isArray(columnOrder)
            ? columnOrder.map((key) => {
                const newKey = idToAccessorKeyMap[key] || key;
                return newKey;
            })
            : [];

    return (
        <>
            {exportData && (
                <ExportToCSV
                    ref={exportRef}
                    data={filteredArrayData}
                    defaultColumns={columnsDatas}
                    selectedReport={selectedReport}
                    columnFilters={columnFilter}
                    reportLastFetched={reportLastFetched}
                    userEmail={userEmail}
                    portalUserApiError={portalUserApiError}
                    reportingPeriodValue={reportingPeriodValue}
                    reportingStatusValue={reportingStatusValue}
                    selectedCoverageType={selectedCoverageType}
                    selectedViewDataType={dataViewType}
                    includeHistoricalLeaveValue={includeHistoricalLeaveValue}
                    periodScheduleTypeValue={periodScheduleTypeValue}
                    periodStatusValue={periodStatusValue}
                    protectionStatusValue={protectionStatusValue}
                    disclaimer={disclaimer}
                    organizationsAccess={organizationsAccess}
                    columnVisibility={updatedColumnVisibility}
                    columnOrder={updatedColumnOrder}
                    ssnDisplayMode={ssnDisplayMode}
                    onExport={handleExportData}
                    organizationName={organizationName}
                    relatedOrganizationNames={relatedOrganizationNames}
                    selectedPolicyDivision={selectedPolicyDivision}
                    selectedReportingGroup={selectedReportingGroup}
                />
            )}
            <ReportTable
                defaultColumns={columnsData()}
                allViewsData={allViewsData}
                handleOptionChange={onViewOptionChange}
                isFetching={isFetching}
                isUninitialized={isUninitialized}
                isError={isError}
                organizationId={organizationIDISF}
                selectedReportView={selectedReportDetails}
                reportData={reportData}
                dataViewType={dataViewType}
                handleViewReportSave={onViewReportSave}
                loaderStatus={loaderStatus}
                handleViewReportUpdate={onViewReportUpdate}
                handleCoverageType={handleCoverageType}
                notificationStatus={notificationStatus}
                description={reportViewInformation[0].Description}
                selectedViewId={reportViewInformation[0].ViewId}
                handleViewID={handleViewID}
                reportViewType={reportViewInformation[0].DataViewType}
                reportLastFetched={reportLastFetched}
                filterValues={filterValues}
                initialFiltersValue={initialFiltersValue}
                portalUserApiError={portalUserApiError}
                organizationsAccess={organizationsAccess}
                emitFiltersDataForSelectedView={emitFiltersDataForSelectedView}
                reportSortingColumnName={
                    reportViewInformation?.[0]?.SortOrder?.ColumnDisplayOrderId
                        ? columnsData()[
                            reportViewInformation[0].SortOrder
                                .ColumnDisplayOrderId - 1
                        ]?.id
                        : []
                }
                reportSortingColumnAccessorKey={
                    reportViewInformation?.[0]?.SortOrder?.ColumnDisplayOrderId
                        ? columnsData()[
                            reportViewInformation[0].SortOrder
                                .ColumnDisplayOrderId - 1
                        ]?.accessorKey : []}
                reportSortingColumnDirection={
                    reportViewInformation?.[0]?.SortOrder?.Direction === "ASC"
                        ? false
                        : true
                }
                totalResults={totalResults}
                isFetchingMore={isFetchingMore}
                portalUserData={portalUserData}
                totalPages={totalPages}
                pagecounter={pagecounter}
                handlePageSelect={handlePageSelect}
                claimData={newFileData}
                isDataFormattingComplete={isDataFormattingComplete}
                getPageCount={getPageCount()}
                formatPageData={formatPageData}
                getPagePerItems={getPagePerItems}
                getSortDeitals={handleSortingChange}
                emitHandleSort={handleSortingChange}
                handleColumnFilter={handleColumnFilter}
                isFetchLoading={isFetchLoading}
                onViewOptionChange={onViewOptionChange}
                onViewReportSave={onViewReportSave}
                onViewReportUpdate={onViewReportUpdate}
                //
                columnFilter={columnFilter}
                setClearColumnFilter={setClearColumnFilter}
                filteredDataLength={filteredDataLength}
                originalDataLength={reportTableData.length}
                handleExportData={handleExportData}
                handlecolumnVisibility={handlecolumnVisibility}
                columnVisibility={columnVisibility}
                handleSsnDisplayMode={handleSsnDisplayMode}
                ssnDisplayMode={ssnDisplayMode}
                handleSelectedReport={handleSelectedReport}
                selectedReport={selectedReport}
            ></ReportTable>
        </>

    );
}

export default ReportViewData;
