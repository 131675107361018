import React, { useState } from "react";

const ReportButtons = ({
    label,
    iconClass,
    onClick,
    ariaLabel,
    isDisabled,
    tabIndex = 0,
    role
}) => {
    return (
        <div
            className={`fs-16 text-semibold align-content-center ${isDisabled ? "disabled-text" : "hyper-links"
                }`}
            role={role}
            aria-label={ariaLabel || label}
            aria-disabled={isDisabled}
            tabIndex={isDisabled ? -1 : 0}
            onClick={() => {
                if (!isDisabled) onClick();
            }}
            onKeyDown={(e) => {
                if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                    e.preventDefault(); // Prevent page scroll for Space key
                    onClick();
                }
            }}
            style={{
                cursor: isDisabled ? "not-allowed" : "pointer",


            }}
            aria-live="polite"
        >
            <span>{label}</span>
            {iconClass && <i className={`${iconClass} pl-8p`} aria-hidden="true"></i>}
            <div
                aria-live="polite"
                style={{ position: "absolute", height: 0, width: 0, overflow: "hidden" }}
            >
                {!isDisabled && `${label} button is active`}
            </div>
        </div>
    );
};

export default ReportButtons;
