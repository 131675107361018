class DataViewFormatter {
    format = (dataViews) => {
        const formattedDataViews = [];

        if (dataViews && Array.isArray(dataViews)) {
            dataViews.forEach((d) => {
                let columnInformation = d.DataViewInfo.TableDisplayInformation?.ColumnInformation;

                if (d.DataViewType.toLowerCase() === "leave") {
                    columnInformation = columnInformation.filter(
                        (col) => !["intermittentDt", "startTime", "endTime", "intermittentAbsenceDisplayName", "reportedDt"].includes(col.AccessorKey)
                    );
                }
                const reportDetails = {
                    ViewId: d.ViewId,
                    DataViewType: d.DataViewType,
                    MostRecentOrder: d.MostRecentOrder,
                    MostUsedOrder: d.MostUsedOrder,
                    Name: d.DataViewInfo.Name,
                    Description: d.DataViewInfo.Description,
                    OwnerType: d.OwnerType,
                    CoverageType: d.DataViewInfo.Parameters.CoverageType,
                    ColumnInformation:
                        columnInformation,
                    SortOrder:
                        d.DataViewInfo.TableDisplayInformation?.SortOrder,
                    SubscriptionInfo: d.SubscriptionInfo,
                    Status: d.DataViewInfo.Parameters.Status,
                    EffectiveStartDate: d.DataViewInfo.Parameters.EffectiveStartDt,
                    EffectiveEndDate: d.DataViewInfo.Parameters.EffectiveEndDt,
                    PeriodScheduleTypes: d.DataViewInfo.Parameters.PeriodScheduleTypes,
                    IncludeHistoricalLeave: d.DataViewInfo.Parameters.IncludeHistoricalLeave,
                    GroupAgreementDivisions: d.DataViewInfo.Parameters.GroupAgreementDivisions,
                    PaymentStatuses: d.DataViewInfo.Parameters.PaymentStatuses,
                    PeriodStatuses: d.DataViewInfo.Parameters.PeriodStatuses,
                    ProductGroupCds: d.DataViewInfo.Parameters.ProductGroupCds,
                    ProtectionStatuses: d.DataViewInfo.Parameters.ProtectionStatuses,
                    ReportingGroups: d.DataViewInfo.Parameters.ReportingGroups,
                    ReportingPeriodMethod: d.DataViewInfo.Parameters.ReportingPeriodMethod,
                    Shared: d.Shared,
                };
                formattedDataViews.push(reportDetails);
            });
        }
        return formattedDataViews;
    };
}

export default new DataViewFormatter();
