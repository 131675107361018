import React, { useEffect, useState } from "react";

import {
    useTable,
    useSortBy,
    usePagination,
    useExpanded,
    useFlexLayout,
} from "react-table";
import { useSticky } from "react-table-sticky";
import PerfectScrollbar from "react-perfect-scrollbar";
import Header from "./Header";
import Row from "./Row";
import Pagination from "./Pagination";

function Table(props) {
    const {
        columns,
        data: initialData,
        onSort = null,
        getTrClasses = null,
        renderRowSubComponent = null,
        manualSortBy = false,
        sticky = false,
        defaultPageSize = 5,
        striped = true,
        bordered = true,
        pagination = true,
        height = "auto",
        note = "",
        className = "",
        baseClassName = "",
        paginationClassName = "",
        print = false,
        fieldName,
        selectedValue,
        pageName,
        responsiveConfig = {
            type: "COLUMN",
            striped: false,
            screen: "md",
            bordered: true,
        },
        style,
        displayReportView
    } = props;

    const defaultColumn = React.useMemo(
        () => ({
            width: 150,
        }),
        []
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        rows,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        toggleRowExpanded,
        state: { pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: defaultPageSize },
            manualSortBy: manualSortBy,
        },
        useFlexLayout,
        useSticky,
        useSortBy,
        useExpanded,
        usePagination
    );

    useEffect(() => {
        if (onSort) onSort(sortBy);
    }, [onSort, sortBy]);

    const _getTrClasses = (row) => {
        if (getTrClasses) return getTrClasses(row);
        return "tr";
    };

    const HEIGHT_FOR_RECORDS = 10;

    const tableData = pagination === true ? page : rows;
    const varBorder = bordered === true ? "bordered " : "borderless ";
    const varStrip = striped === true ? "striped " : "solid ";
    const scrollCondClass =
        pagination === true ? "" : "table-body-scroll stop-scroll-chaining";
    const responsiveBorder =
        responsiveConfig.bordered === true
            ? "responsive-bordered "
            : "responsive-borderless ";
    const responsiveStrip =
        responsiveConfig.striped === true
            ? " responsive-striped "
            : " responsive-solid ";

    return (
        <>
            {sticky === true ? (
                <div className="sticky-table">
                    <PerfectScrollbar
                        {...getTableProps()}
                        className={
                            "table mb-0 sticky custom-scrollbar " +
                            varStrip +
                            varBorder +
                            responsiveBorder +
                            className
                        }
                    >
                        <Header headerGroups={headerGroups} style={style} />

                        <div {...getTableBodyProps()} className="body">
                            {tableData.map((row, i) => {
                                prepareRow(row);
                                const trClassName = _getTrClasses(row);

                                return (
                                    <Row
                                        key={i}
                                        row={row}
                                        toggleRowExpanded={null}
                                        trClassName={trClassName}
                                        print={print}
                                        renderRowSubComponent={
                                            renderRowSubComponent
                                        }
                                        responsiveConfig={responsiveConfig}
                                    />
                                );
                            })}
                        </div>
                    </PerfectScrollbar>
                </div>
            ) : (
                <>
                    <div
                        className={
                            "base-table  " +
                            `responsive-${responsiveConfig.screen} ` +
                            responsiveStrip +
                            responsiveBorder +
                            baseClassName
                        }
                    >
                        <PerfectScrollbar
                            {...getTableProps()}
                            className={
                                "table mb-0 " + varStrip + varBorder + className
                            }
                        >
                            <Header headerGroups={headerGroups} style={style} displayReportView={displayReportView} />

                            <PerfectScrollbar
                                {...getTableBodyProps()}
                                className={"body " + scrollCondClass}
                                style={{
                                    height:
                                        pagination === true &&
                                            page?.length > 9 ? Math.min(
                                                height,
                                                tableData.length *
                                                (height /
                                                    HEIGHT_FOR_RECORDS)
                                            ) : "auto"
                                }}
                            >
                                {tableData.map((row, i) => {
                                    prepareRow(row);

                                    const trClassName = _getTrClasses(row);

                                    return (
                                        <Row
                                            key={i}
                                            row={row}
                                            toggleRowExpanded={
                                                toggleRowExpanded
                                            }
                                            trClassName={trClassName}
                                            renderRowSubComponent={
                                                renderRowSubComponent
                                            }
                                            print={print}
                                            responsiveConfig={responsiveConfig}
                                            displayReportView={displayReportView}
                                        />
                                    );
                                })}
                            </PerfectScrollbar>
                        </PerfectScrollbar>
                    </div>
                </>
            )}

            {note !== "" && (
                <p className="px-20p fs-xs text-semibold">{note}</p>
            )}

            {pagination === true && (
                <>
                    <Pagination
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        paginationClassName={paginationClassName}
                        fieldName={fieldName}
                        selectedValue={selectedValue}
                        pageName={pageName}
                    />
                </>
            )}
        </>
    );
}

export default Table;
