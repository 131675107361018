import React, { useState, useEffect, useCallback } from "react";
import RelatedOrganization from "./RelatedOrganization";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import EmployeesTable from "./EmployeesTable";
import {
    cacheDataForInSeconds,
    defaultPaginationItems,
    ssnAccess,
} from "../../constants";
import Select from "react-select";
import EmployeeSearchFilterTags from "./EmployeeSearchFilterTags";
import { persist } from "./../../slices/historySlice";
import NoRecords from "../../components/alerts/NoRecords";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import FooterNote from "../claimleaveview/FooterNote";
import Input from "../../components/ui/Input";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import employeesApiSlice, {
    useLazyGetEmployeesQuery,
} from "../../slices/employeesApiSlice";
import Loader from "../../components/ui/Loader";
import RequestFailed from "../../components/alerts/RequestFailed";
import { isNumeric, unixTimestampToDate } from "./../../utils/helpers";
import { logout } from "./../../slices/authSlice";
import { setCurrentSelectedOrganization } from "../../slices/organizationSlice";
import GTM from "../../GTM";

import { setUserPreference } from "../../slices/preferenceSlice";
import close from "../../assets/icons/close-icon.svg";
import { clearSelectedOrganizationsForReports } from "../../slices/viewReportSlice";
import { setSelectedRelatedOrganizationsForReports } from "../../slices/viewReportSlice";

function EmployeeSearch(props) {
    const gtm = new GTM(null);
    const { user, returnUrl } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    const myunum = "my.unum.com";
    const { lastSelectedOrganization } = useSelector(
        (state) => state.organizations
    );
    const pageReferrer = () => {
        return !user?.isUserInternal &&
            page_referrer?.includes("/login") &&
            returnUrl?.includes("uaservices")
            ? config.REACT_APP_ISERVICES + "/_fwWelc/Welcome.aspx"
            : page_referrer?.includes("/login") && returnUrl?.includes(myunum)
                ? config.REACT_APP_MY_UNUM
                : config.REACT_APP_BASE_URL + page_referrer;
    };

    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];

    let page_referrer_url = pageReferrer();

    let page_referrer_title = "";
    const employeePage = /organization\/[^\/]+\/employees\/search/s;
    const claimLeavePage = /organization\/[^\/]+\/employees\/[^\/]+/gm;

    if (
        String(page_referrer_url)?.includes("/login") &&
        !returnUrl?.includes(myunum)
    ) {
        page_referrer_title = "iservices";
    } else if (String(page_referrer_url)?.match(claimLeavePage) !== null) {
        page_referrer_title = "claim & leave";
    } else if (String(page_referrer_url)?.match(employeePage) !== null) {
        page_referrer_title = "employee";
    } else if (
        String(page_referrer_url)?.includes("/login") &&
        returnUrl?.includes(myunum)
    ) {
        page_referrer_title = " myunum for clients";
    } else {
        page_referrer_title = "search";
    }

    useEffect(() => {
        const userName =
            lastSelectedOrganization?.ORGNIZATIONNAME.toLowerCase();
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal === true ? "internal" : "external";

        gtm.PageView(
            "employee",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL + page_referrer}`,
            page_referrer_title,
            id
        );
    }, []);

    const filterInitialState = {
        firstName: "",
        lastName: "",
        claimNumber: "",
        leaveNumber: "",
        eeid: "",
        ssn: "",
    };

    let filterOptions = [
        { value: "Last Name", label: "Last Name", key: "lastName" },
        { value: "EEID", label: "EEID", key: "eeid" },
        { value: "First Name", label: "First Name", key: "firstName" },
        { value: "Claim Number", label: "Claim Number", key: "claimNumber" },
        { value: "Leave Number", label: "Leave Number", key: "leaveNumber" },
        { value: "SSN", label: "SSN", key: "ssn" },
    ];

    const {
        searchPreference,
        organizationPreference,
        userSelectedOrganizationPreference,
        homePreference,
    } = useSelector((state) => state.preferenceSlice);

    const {
        selectedRelatedOrganizationsForReports:
        selectedRelatedOrganizationsForReports,
    } = useSelector((state) => state.reportOrganization);

    const userPreference =
        searchPreference && searchPreference.length > 0
            ? searchPreference[0]
            : [];

    const initialDropdownValue = userPreference
        ? filterOptions.find((option) => option.key === userPreference)
        : null;

    const [relatedOrganizations, setRelatedOrganizations] = useState([]);
    const [relatedOrganizationOptions, setRelatedOrganizationOptions] =
        useState([]);
    const [selectedRelatedOrganizations, setSelectedRelatedOrganizations] =
        useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [isOrgLoading, setIsOrgLoading] = useState(true);

    const [tableItems, setTableItems] = useState(defaultPaginationItems);

    const [filterValue, setFilterValue] = useState("");
    const [errors, setErrors] = useState({});

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        selectedOrganizations: userSelectedOrganizations,
        organizationsAccess,
        organizationsCount: organizationCount,
    } = useSelector((state) => state.organizations);

    useEffect(() => {
        dispatch(setCurrentSelectedOrganization(id));

        if (id in userSelectedOrganizations) {
            setIsOrgLoading(false);
            setSelectedOrganization(userSelectedOrganizations[id]?.organization);
            setRelatedOrganizations(userSelectedOrganizations[id]?.related);
        } else {
            navigate(`/`, { replace: true });
        }
    }, []);

    const [filters, setFilters] = useState(filterInitialState);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState(
        initialDropdownValue ? initialDropdownValue : filterOptions[0]
    );

    const [
        getEmployees,
        {
            data: employees = [],
            isFetching,
            isError,
            isUninitialized,
            isSuccess,
            error,
            fulfilledTimeStamp,
            originalArgs,
        },
    ] = useLazyGetEmployeesQuery();

    useEffect(() => {
        dispatch(
            persist({
                key: url,
                updatedTimestamp: fulfilledTimeStamp,
            })
        );
    }, [fulfilledTimeStamp]);

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
        }
    }, [error]);

    const url = window.location.pathname;

    const dispatch = useDispatch();
    const stateHistory = useSelector(
        (state) => state.history[window.location.pathname]
    );

    //preference - releted org
    useEffect(() => {
        if (
            organizationPreference &&
            organizationPreference.length > 1 &&
            organizationPreference !== undefined
        ) {
            const preselectedOrgs = relatedOrganizations.filter((org) =>
                organizationPreference.includes(org.ORGANIZATIONID)
            );
            setSelectedRelatedOrganizations(
                preselectedOrgs.map((org) => ({
                    label: org.ORGNIZATIONNAME,
                    value: org.ORGANIZATIONID,
                }))
            );
        }
    }, [relatedOrganizations, organizationPreference]);

    // Build React Select Options for Related Organizations
    useEffect(() => {
        const options = [];
        const selected = [];
        if (
            organizationPreference &&
            organizationPreference.length > 0 &&
            organizationPreference.toString() !== "" &&
            organizationPreference !== undefined
        ) {
            relatedOrganizations.forEach((item) => {
                const option = {
                    value: item.ORGANIZATIONID,
                    label: item.ORGNIZATIONNAME,
                };
                options.push(option);

                if (
                    organizationPreference.includes(String(item.ORGANIZATIONID))
                ) {
                    selected.push(option);
                }
            });

            const sortedOption = [...options].sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });

            setRelatedOrganizationOptions(sortedOption);
        } else if (!organizationCount || relatedOrganizations.length === 1) {
            relatedOrganizations.forEach((item) => {
                const option = {
                    value: item.ORGANIZATIONID,
                    label: item.ORGNIZATIONNAME,
                };
                options.push(option);
                if (parseInt(id) === item.ORGANIZATIONID) {
                    selected.push(option);
                }
            });
            const sortedOption = [...options].sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
            setRelatedOrganizationOptions(sortedOption);
        } else {
            let filteredOptions = relatedOrganizations.map((item) => ({
                value: item.ORGANIZATIONID,
                label: item.ORGNIZATIONNAME,
            }));

            filteredOptions = filteredOptions.filter((option) => option.value);

            const sortedOption = [...filteredOptions].sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });

            setRelatedOrganizationOptions(sortedOption);
        }

        if (
            selected.length > 0 &&
            !stateHistory?.selectedRelatedOrganizations
        ) {
            if (selectedRelatedOrganizationsForReports.length > 0) {
                setSelectedRelatedOrganizations(
                    selectedRelatedOrganizationsForReports
                );
            } else {
                setSelectedRelatedOrganizations(selected);
            }
        }
    }, [relatedOrganizations, user.isUserInternal, id]);

    useEffect(() => {
        if (selectedRelatedOrganizationsForReports.length > 0) {
            setSelectedRelatedOrganizations(selectedRelatedOrganizationsForReports)
        }
    }, [selectedRelatedOrganizationsForReports])

    // Clear persisted state in store
    const clearHistory = () => {
        dispatch(
            persist({
                key: url,
                selectedRelatedOrganizations: [],
                filters: filterInitialState,
            })
        );
    };

    const preferCacheData = (t) => {
        if (t && isNumeric(t)) {
            const diff = new Date().getTime() - t;

            if (diff <= cacheDataForInSeconds * 1000) {
                return true;
            }
        }

        return false;
    };

    // Persist & rehydrate state
    useEffect(() => {
        if (stateHistory?.selectedRelatedOrganizations) {
            setSelectedRelatedOrganizations(
                stateHistory.selectedRelatedOrganizations
            );
        }

        if (stateHistory?.filters) {
            let appliedFilters = 0;

            for (let key in stateHistory.filters) {
                if (stateHistory.filters[key].trim() !== "") {
                    appliedFilters += 1;
                }
            }

            if (appliedFilters > 0) {
                setFilters(stateHistory.filters);

                getEmployees(
                    {
                        organizationId: getOrganizationIdsForSearch(
                            stateHistory?.selectedRelatedOrganizations
                        ),
                        filters: stateHistory.filters,
                    },
                    preferCacheData(stateHistory?.updatedTimestamp)
                );
            }
        }
    }, []);
    const pageTitle = "searching an employee from an organization";

    const handleRelatedOrganizations = useCallback((orgs) => {
        setSelectedRelatedOrganizations(orgs);
        dispatch(setSelectedRelatedOrganizationsForReports(orgs));
        const organization = orgs.map((o) => o.label.toLowerCase());

        gtm.Event(
            pageTitle,
            `${orgs.length ? "dropdown" : "button"}`,
            `${orgs.length ? "select" : "click"}`,
            `${orgs.length ? "related organizations" : "clear"}`,
            "org selection",
            "",
            `${orgs.length
                ? "field_interaction"
                : "clear_selected_organization"
            }`,
            `${orgs.length ? organization : ""}`
        );
    }, []);

    const handleItemsPerPageChange = useCallback((e) => {
        setTableItems(e);
    }, []);

    const gtmEvent = (
        page,
        category,
        action,
        label,
        attribute3,
        attribute4
    ) => {
        gtm.Event(
            page,
            category,
            action,
            label,
            "employee search",
            "",
            attribute3,
            attribute4 ? attribute4 : ""
        );
    };

    const removeFilter = (filterKey) => {
        const clone = { ...filters };
        clone[filterKey] = "";

        setFilters(clone);

        const existingFilters = filterOptions.filter(
            (option) => clone[option.key] !== ""
        );

        if (existingFilters.length === 0) {
            dispatch(employeesApiSlice.util.resetApiState());
            setSelectedDropdownValue(
                initialDropdownValue ? initialDropdownValue : filterOptions[0]
            );
        }
    };

    const addFilter = () => {
        const clone = { ...filters };

        if (selectedDropdownValue && selectedDropdownValue.key === "ssn") {
            const regX = /^([0-9 -]{11})$/;

            if (!filterValue.match(regX)) {
                setErrors({
                    ...errors,
                    filterValue: "9 digits required for SSN",
                });

                return clone;
            }
        }

        if (selectedDropdownValue && filterValue.trim() !== "") {
            setErrors({
                ...errors,
                filterValue: "",
            });

            clone[selectedDropdownValue.key] = filterValue;

            setFilters(clone);
            setFilterValue("");
            setSelectedDropdownValue(null);
            gtmEvent(
                pageTitle,
                "button",
                "click",
                "filter",
                "apply_filter",
                selectedDropdownValue.value
            );
        }

        return clone;
    };

    const handleChange = ({ target: input }) => {
        let value = input.value;

        if (selectedDropdownValue && selectedDropdownValue.key === "ssn")
            value = formatSSN(value);

        setFilterValue(value);
        if (value.nativeEvent?.inputType === "deleteContentBackward") {
            setFilterValue(filterValue.slice(0, -1));
        } else {
            setFilterValue(value);
        }
    };
    const handleTextboxFocus = () => {
        gtm.Event(
            pageTitle,
            "textbox",
            "interact",
            `${selectedDropdownValue?.label.toLocaleLowerCase()}`,
            "search",
            "",
            "field_interaction",
            ""
        );
    };

    const handleSelectedDropdownChange = useCallback((searchOption) => {
        setSelectedDropdownValue(searchOption);

        setErrors({});

        if (searchOption.key === "ssn") {
            setFilterValue(formatSSN(filterValue));
        }
        gtmEvent(
            "searching for an employee from an organisation",
            "dropdown",
            "select",
            "search by",
            "field_interaction",
            searchOption.value
        );
    });

    const handleSubmit = () => {
        handleEmployeesSearch();

        setTableItems(defaultPaginationItems);
        if (selectedDropdownValue) {
            gtm.Event(
                pageTitle,
                "button",
                "click",
                "search",
                "employee search",
                "",
                "field_interaction",
                selectedDropdownValue.value
            );
        } else {
            gtmEvent(
                pageTitle,
                "button",
                "click",
                "search",
                "field_interaction"
            );
        }
    };

    const handleClear = () => {
        setFilters(filterInitialState);

        setErrors({});
        setSelectedDropdownValue(
            initialDropdownValue ? initialDropdownValue : filterOptions[0]
        );

        clearHistory();
        dispatch(employeesApiSlice.util.resetApiState());
        gtmEvent(pageTitle, "button", "click", "clear", "clear_filter");
    };

    const formatSSN = (val) => {
        val = val.replace(/\D/g, "");
        val = val.replace(/^(\d{3})/, "$1-");
        val = val.replace(/-(\d{2})/, "-$1-");
        val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
        return val;
    };

    const getOrganizationIdsForSearch = (
        selectedRelatedOrganizationsSearch
    ) => {
        if (
            selectedRelatedOrganizationsSearch &&
            Array.isArray(selectedRelatedOrganizationsSearch) &&
            selectedRelatedOrganizationsSearch.length > 0
        ) {
            const selectedOrgIds = [];

            selectedRelatedOrganizationsSearch.forEach((o) =>
                selectedOrgIds.push(o.value)
            );

            return selectedOrgIds.join(",");
        }

        return id;
    };

    const handleEmployeesSearch = async () => {
        const searchFilters = addFilter();

        const ssnValidation =
            !selectedDropdownValue ||
            selectedDropdownValue.key !== "ssn" ||
            searchFilters.ssn.match(/^([0-9 -]{11})$/);

        if (!ssnValidation) return;

        getEmployees({
            organizationId: getOrganizationIdsForSearch(
                selectedRelatedOrganizations
            ),
            filters: searchFilters,
        });

        dispatch(
            persist({
                key: url,
                selectedRelatedOrganizations,
                filters: searchFilters,
            })
        );
    };

    const isSsnValid = () => {
        return (
            selectedDropdownValue &&
            selectedDropdownValue.key === "ssn" &&
            filterValue.match(/^([0-9 -]{11})$/)
        );
    };

    const setClassName = useCallback(() => {
        return errors["dropdownValue"] ? "errorfield" : "select-dropdown";
    });

    const showSSNOption = () => {
        return (
            id in organizationsAccess &&
            organizationsAccess[id].SSNACCESS !== ssnAccess["No Access"]
        );
    };

    const removeOrgPreference = () => {
        dispatch(
            setUserPreference({
                homePreference: homePreference,
                organizationPreference: [""],
                userSelectedOrganizationPreference:
                    userSelectedOrganizationPreference,
                searchPreference: searchPreference,
            })
        );
        dispatch(clearSelectedOrganizationsForReports());

        navigate("/search");
    };

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        name: "Employee",
                    },
                ]}
                tabIndex={0}
            />

            {relatedOrganizations.length > 0 && (
                <RelatedOrganization
                    relatedOrganizations={relatedOrganizationOptions}
                    selectedOrganization={selectedOrganization}
                    handleRelatedOrganizations={handleRelatedOrganizations}
                    selectedRelatedOrganizations={selectedRelatedOrganizations}
                    removeOrgPreference={removeOrgPreference}
                    organizationPreference={organizationPreference}
                />
            )}
            {isOrgLoading ? (
                <Loader />
            ) : (
                (selectedRelatedOrganizations.length > 0 ||
                    selectedOrganization?.ORGANIZATIONGROUPID === null) && (
                    <div className="section-card bg-even">
                        <div className="row">
                            <div className="col-md-12 d-flex">
                                <div className="d-flex align-items-start">
                                    {!relatedOrganizations?.length > 0 && (
                                        <SectionHeading
                                            heading={
                                                selectedOrganization?.ORGNIZATIONNAME
                                            }
                                        />
                                    )}
                                    {organizationPreference !== null &&
                                        organizationPreference?.toString() !==
                                        "" &&
                                        organizationPreference &&
                                        organizationPreference !== undefined &&
                                        !relatedOrganizations?.length > 0 && (
                                            <img
                                                className="closeicon ms-2"
                                                src={close}
                                                onClick={removeOrgPreference}
                                                alt="closeIcon"
                                                width="25px"
                                            />
                                        )}

                                    {filterOptions.filter(
                                        (option) => filters[option.key] !== ""
                                    ).length !== 0 && (
                                            <EmployeeSearchFilterTags
                                                filters={filters}
                                                removeFilter={removeFilter}
                                                relatedOrganizations={
                                                    relatedOrganizations
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                        <label className="fs-body md-tp-25">Search By</label>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 col-6 md-mb-20p td-mb-20p ">
                                <div>
                                    <Select
                                        className={setClassName}
                                        name="dropdownValue"
                                        value={selectedDropdownValue}
                                        options={filterOptions
                                            .filter(
                                                (option) =>
                                                    option.key !== "ssn" ||
                                                    (option.key === "ssn" &&
                                                        showSSNOption())
                                            )
                                            .filter(
                                                (option) =>
                                                    filters[option.key] === ""
                                            )}
                                        onChange={handleSelectedDropdownChange}
                                        isSearchable={false}
                                        tabIndex="0"
                                    />

                                    {errors["dropdownValue"] && (
                                        <span className="errormsg">
                                            {errors["dropdownValue"]}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 col-6 md-mb-20p">
                                <Input
                                    value={filterValue}
                                    name="filterValue"
                                    onChange={handleChange}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            handleEmployeesSearch();
                                            setTableItems(
                                                defaultPaginationItems
                                            );
                                        }
                                    }}
                                    error={
                                        errors["filterValue"] && !isSsnValid()
                                    }
                                    success={isSsnValid()}
                                    message={errors["filterValue"]}
                                    autoComplete="off"
                                    onBlur={handleTextboxFocus}
                                />
                            </div>

                            {filterOptions.filter(
                                (option) => filters[option.key] === ""
                            ).length !== 0 && (
                                    <div className="col-lg-2 col-sm-4 col-4 sm-mb-20p">
                                        <Button
                                            onClick={() => {
                                                addFilter();
                                            }}
                                            disabled={filterValue.trim() === ""}
                                            text="Filter"
                                            icon="fa-filter"
                                        />
                                    </div>
                                )}

                            <div className="col-lg-2 col-sm-4 col-4 sm-mb-20p">
                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={
                                        (filterOptions.filter(
                                            (option) =>
                                                filters[option.key] !== ""
                                        ).length === 0 &&
                                            filterValue.trim() === "") ||
                                        (relatedOrganizations.length > 0 &&
                                            selectedRelatedOrganizations.length ===
                                            0)
                                    }
                                    text="Search"
                                    icon="fa-magnifying-glass"
                                />
                            </div>

                            {filterOptions.filter(
                                (option) => filters[option.key] !== ""
                            ).length > 0 && (
                                    <div className="col-lg-2 col-sm-4 col-4">
                                        <Button
                                            onClick={() => {
                                                handleClear();
                                            }}
                                            text="Clear"
                                            icon="fa-xmark"
                                            outline={true}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                )
            )}

            {!isUninitialized &&
                !isFetching &&
                !isError &&
                employees.length === 0 && (
                    <NoRecords
                        page="employee"
                        label="search"
                        attribute1="employee search"
                        attribute3="employee_error"
                    />
                )}

            {isFetching && <Loader />}
            {isError && (
                <RequestFailed
                    tabIndex={0}
                    message={
                        "Your search failed, please try again or contact us for further assistance."
                    }
                    label="search"
                    attribute1="employee search"
                    attribute3="employee_error"
                />
            )}

            {!isFetching && isSuccess && employees.length > 0 && (
                <>
                    <EmployeesTable
                        filteredEmployees={employees}
                        rowRangeChanged={tableItems}
                        ssnDisplay={
                            organizationsAccess[employees[0].OrganizationId]
                                ?.SSNACCESS
                        }
                        relatedOrganizationsCount={relatedOrganizations.length}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        selectedOrganization={selectedOrganization}
                        relatedIds={originalArgs?.organizationId}
                    />

                    <FooterNote
                        date={unixTimestampToDate(fulfilledTimeStamp)}
                    />
                </>
            )}
        </>
    );
}

export default EmployeeSearch;
