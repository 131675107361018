import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import Button from "../../components/ui/Button";
import { MultiSelect } from "react-multi-select-component";
import GTM from "../../GTM";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
    getPresentDayFormat,
    getPreviousDays,
    getPreviousDaysEnd,
    getPreviousDaysStart,
    CUSTOM_DATE,
    PREVIOUS_MONTH,
    PREVIOUS_QUARTER,
    TODAY,
    PRIOR_BUSINESS_DAY,
    ONE_WEEK,
    PREVIOUS_DAY,
    LAST_7_DAYS,
} from "../../utils/helpers";
import PoliciesAndDivisions from "./PoliciesAndDivisions";
import { FaAngleRight } from "react-icons/fa"; // Import the arrow icons
import moment from "moment";
import {
    absenceType,
    disabilityType,
    leaveType,
    paymentHistoryType,
    changesReportType,
} from "../../constants";
import DropDownIcon from "../../components/icons/DropdownIcon";
import MultiSelectComponent from "../../components/MultiSelect"

const ReportFilters = ({
    allowedPoliciesAndDivisions,
    handleApplyFilter,
    selectedValues,
    updateSelectedValues,
    coverageTypeOptions,
    setSelectedCoverageType,
    reportingPeriod,
    status,
    reportingGroup,
    reportRanDate,
    reportViewType,
    defaultReportingPeriod,
    customDateRange,
    handleDateRangeChange,
    organizationName,
    userSelectedOrganizationReports,
    reportId,
    relatedOrganizationNames,
    selectedCoverageType,
    selectedStatus,
    setSelectedStatus,
    selectedPolicyDivision,
    selectedReportingGroup,
    handlePolicyDivision,
    includeHistoricalLeave,
    periodScheduleType,
    periodStatus,
    protectionStatus,
    defaultIncludeHistoricalLeaveValue,
    defaultPeriodStatusValue,
    defaultPeriodScheduleTypeValue,
    defaultProtectionStatusValue,
    onIncludeHistoricalValueChange,
    onReportingPeriodChange,
    onHandleReset,
    enableReset,
    enableApply,
    onHandleOutsideClick,
    disableConfirm,
    setEnableReset,
    setEnableApplyButton,
    setPeriodScheduleTypeValue,
    setPeriodStatusValue,
    setProtectionStatusValue,
    setSelectedReportingGroup
}) => {
    const gtm = new GTM(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const { displayReportingGroupFilter } = useFlags();
    const [initialDateRange, setIntitalDateRange] = useState(customDateRange);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [isHistoricalLeaveDropdowOpen, setIsHistoricalLeaveDropdowOpen] = useState(false);
    const historicalLeaveToggleRef = useRef(null);
    const historicalLeaveDropdownRef = useRef(null);
    const optionRefs = useRef([]);
    const handleGtmTags = (label) => {
        gtm.Event(
            "custom_event",
            "filter",
            "select",
            label,
            "report detail",
            reportViewType.toLowerCase(),
            "field_interaction",
            ""
        );
    };
    const [enableApplyButton, setEnableApply] = useState(enableApply);
    const handleToggleHistoricalLeaveDropdown = () => {
        setIsHistoricalLeaveDropdowOpen(!isHistoricalLeaveDropdowOpen);
        const defaultIndex = includeHistoricalLeave.findIndex(
            (option) => option.value === defaultIncludeHistoricalLeaveValue.value
        );
        setFocusedIndex(defaultIndex >= 0 ? defaultIndex : null);

    }

    const commonSelectProps = {
        isSearchable: false,
        styles: {
            control: (provided, state) => ({
                ...provided,
                borderTop: "1px",
                borderColor: "none",
                borderLeft: "0px",
                borderRight: "1px",
                borderRadius: "0px",
                marginTop: "-12px",
                boxShadow: state.isFocused && "none",
                backgroundColor: "none",
                color: state.isSelected && "white",
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "#FFFFFF" : "black",
                backgroundColor: state.isSelected && "#037CB7",
                margin: "0px",
                "&:hover": {
                    backgroundColor: "#037CB7",
                    color: "#FFFFFF",
                    cursor: "pointer",
                },
            }),
            placeholder: (provided) => ({
                ...provided,
                color: "#fffff",
            }),
            singleValue: (provided) => ({
                ...provided,
                marginLeft: "0px",
                color: "white",
            }),
            valueContainer: (provided) => ({
                ...provided,
                paddingLeft: "0px",
            }),
            menu: (provided) => ({
                ...provided,
                margin: "0px",
                zIndex: 10,
            }),
        },
        components: {
            DropdownIndicator: ({ selectProps }) => {
                if (selectProps.category === "reportingPeriod") {
                    return (
                        <div>
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginRight: 8 }}
                            ></i>
                            <i className="fa-solid fa-calendar"></i>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginRight: 8 }}
                            ></i>
                        </div>
                    );
                }
            },
        },
    };

    const CustomOption = ({ children, ...props }) => {
        return (
            <components.Option {...props}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {children}
                    {props.data.value === CUSTOM_DATE && (
                        <FaAngleRight
                            style={{ marginLeft: "auto", fontSize: "1.3em" }}
                        />
                    )}
                </div>
            </components.Option>
        );
    };
    const handleReportingPeriodChange = (selectedOption) => {
        if (selectedOption.value !== CUSTOM_DATE) {
            onReportingPeriodChange(selectedOption);
            //setEnableApply(true);
            updateSelectedValues({
                ...selectedValues,
                reportingPeriod: selectedOption,
            });
        }
        handleGtmTags("reporting period");

        if (selectedOption.value === CUSTOM_DATE) {
            setShowCalendar(true); // Show the date range picker
        } else {
            setShowCalendar(false);
            handleDateRangeChange({
                reset: true,
            });
        }
    };

    const handleClearDates = () => {

        handleDateRangeChange({
            reset: true,
        });
    };

    const handleConfirmDates = () => {
        setIntitalDateRange(customDateRange);
        handleDateRangeChange({
            confirm: true,
        });
        setShowCalendar(false); // Show the date range picker
        updateSelectedValues({
            ...selectedValues,
            reportingPeriod: {
                value: CUSTOM_DATE,
                label:
                    CUSTOM_DATE +
                    moment(customDateRange[0].startDate).format(mmDateFormat) +
                    " - " +
                    moment(customDateRange[0].endDate).format(mmDateFormat),
            },
        });
    };

    const [showCalendar, setShowCalendar] = useState(false);
    const daterangeref = useRef(null);
    const popupRef = useRef(null);
    const isClickedOutside = useOutsideClick(daterangeref, popupRef);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                historicalLeaveDropdownRef.current &&
                !historicalLeaveDropdownRef.current.contains(event.target) &&
                !historicalLeaveToggleRef.current.contains(event.target)
            ) {
                setIsHistoricalLeaveDropdowOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (!isHistoricalLeaveDropdowOpen) return;

        const options = Array.from(
            historicalLeaveDropdownRef.current.querySelectorAll(".select-option")
        );

        const focusOption = (index) => {
            if (options[index]) {
                options[index].focus();
            }
        };

        const handleKeyDown = (e) => {
            const focusedElement = document.activeElement;
            const currentIndex = options.findIndex((option) => option === focusedElement);

            switch (e.key) {
                case "Tab":
                    e.preventDefault();
                    const newIndexTab = e.shiftKey
                        ? (currentIndex - 1 + options.length) % options.length
                        : (currentIndex + 1) % options.length;
                    focusOption(newIndexTab);
                    break;

                case "Escape":
                    e.preventDefault();
                    setIsHistoricalLeaveDropdowOpen(false);
                    break;

                case "ArrowDown":
                    e.preventDefault();
                    focusOption((currentIndex + 1) % options.length);
                    break;

                case "ArrowUp":
                    e.preventDefault();
                    focusOption((currentIndex - 1 + options.length) % options.length);
                    break;

                case "Enter":
                    if (focusedElement.classList.contains("select-option")) {
                        const selectedOption = includeHistoricalLeave.find(
                            (option) => option.label === focusedElement.textContent.trim()
                        );
                        if (selectedOption) {
                            handleIncludeHistoricalValueChange(selectedOption);
                            setIsHistoricalLeaveDropdowOpen(false);
                        }
                    }
                    break;

                default:
                    break;
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isHistoricalLeaveDropdowOpen]);

    const handleHistoricalLeaveKeyDown = (e) => {
        switch (e.key) {
            case "Enter":
            case " ":
                e.preventDefault();
                handleToggleHistoricalLeaveDropdown();
                break;

            case "ArrowDown":
                e.preventDefault();
                setIsHistoricalLeaveDropdowOpen(true);
                const defaultIndex = includeHistoricalLeave.findIndex(
                    (option) => option.value === defaultIncludeHistoricalLeaveValue.value
                );
                setFocusedIndex(defaultIndex >= 0 ? defaultIndex : null);
                break;

            case "Escape":
                e.preventDefault();
                setIsHistoricalLeaveDropdowOpen(false);
                setFocusedIndex(null);
                break;

            default:
                break;
        }
    };



    const handlePolicyCheckboxChange = (policyId) => {
        const policyDivisions =
            filteredPoliciesAndDivisions.find(
                (item) => item.policyId === policyId
            )?.divisions || [];
        const divisionIds = policyDivisions.map(
            (division) => `${policyId}-${division.divisionId}`
        );

        handlePolicyDivision((prevSelected) => {
            if (prevSelected.includes(policyId)) {
                return prevSelected.filter(
                    (item) => item !== policyId && !divisionIds.includes(item)
                );
            } else {
                return [...prevSelected, policyId, ...divisionIds];
            }
        });
    };

    const filteredPoliciesAndDivisions =
        searchTerm.length < 3
            ? allowedPoliciesAndDivisions
            : allowedPoliciesAndDivisions.filter((item) => {
                return (
                    item.policyId
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    item.divisions.some(
                        (division) =>
                            division.divisionName
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                            division.divisionId
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase())
                    )
                );
            });

    const handleDivisionCheckboxChange = (divisionId, policyId) => {
        handlePolicyDivision((prevSelected) => {
            const policyDivisions =
                filteredPoliciesAndDivisions
                    .find((item) => item.policyId === policyId)
                    ?.divisions.map(
                        (division) => `${policyId}-${division.divisionId}`
                    ) || [];

            const newSelected = prevSelected.includes(divisionId)
                ? prevSelected.filter((item) => item !== divisionId)
                : [...prevSelected, divisionId];

            const allPolicyDivisionsSelected = policyDivisions.every((id) =>
                newSelected.includes(id)
            );

            if (allPolicyDivisionsSelected) {
                return [...newSelected, policyId];
            } else {
                return newSelected.filter((item) => item !== policyId);
            }
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectAll = () => {
        const allItems = filteredPoliciesAndDivisions.flatMap((item) => [
            item.policyId,
            ...item.divisions.map(
                (division) => `${item.policyId}-${division.divisionId}`
            ),
        ]);
        handlePolicyDivision(allItems);
    };

    const handleUnselectAll = () => {
        handlePolicyDivision([]);
    };

    const clearSearch = () => {
        setSearchTerm("");
    };

    const handleReset = () => {
        onHandleReset();
        handleGtmTags("reset filters");
    };

    useEffect(() => {
        if (showCalendar) {
            setShowCalendar(false);
            onHandleOutsideClick();
            customDateRange = initialDateRange
            handleDateRangeChange({
                confirm: false,
                outsideclick: true,
                dateValue: initialDateRange
            });
        }
    }, [isClickedOutside]);

    useEffect(() => {
        setEnableApply(false);
    }, [enableApply]);

    const minimumStartDate = new Date();
    if (reportViewType === changesReportType) {
        minimumStartDate.setDate(minimumStartDate.getDate() - 30);
    } else {
        minimumStartDate.setFullYear(minimumStartDate.getFullYear() - 5);
    }

    const onApplyFilterClick = (item) => {
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        if (item.reportingPeriod.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 1);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 1);
        } else if (item.reportingPeriod.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 7);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 1);
        } else if (item.reportingPeriod.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportRanDate,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(reportRanDate, "month");
        } else if (item.reportingPeriod.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportRanDate,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportRanDate,
                "quarter"
            );
        } else if (item.reportingPeriod.value === TODAY) {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 0);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 0);
        } else if (item.reportingPeriod.value === PRIOR_BUSINESS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 1);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 0);
        } else if (item.reportingPeriod.value === ONE_WEEK) {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 7);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 1);
        } else if (item.reportingPeriod.value === CUSTOM_DATE) {
            reportingPeriodStartDate = customDateRange[0].startDate;
            reportingPeriodEndDate = customDateRange[0].endDate;
        }
        handleApplyFilter({
            ...item,
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
        });
        handleGtmTags("apply filters");
    };
    let mmDateFormat = "MM/DD/YYYY";
    let fromPlaceholder = "";
    if (customDateRange[0].selectedFromDate) {
        fromPlaceholder = `Start Date: ${moment(
            customDateRange[0].selectedFromDate
        ).format(mmDateFormat)}`;
    } else {
        fromPlaceholder = "Start Date:"; // Placeholder text when startDate is null
    }

    let toPlaceholder = "";
    if (customDateRange[0].selectedEndDate) {
        toPlaceholder = `End Date: ${moment(
            customDateRange[0].selectedEndDate
        ).format(mmDateFormat)}`;
    } else {
        toPlaceholder = "End Date:"; // Placeholder text when endDate is null
    }

    const handleIncludeHistoricalValueChange = (selectedOptions) => {
        setIsHistoricalLeaveDropdowOpen(!isHistoricalLeaveDropdowOpen);
        onIncludeHistoricalValueChange(selectedOptions);
    };


    // Determine border styles based on placeholder values and their conditions
    const fromIsStartDate = fromPlaceholder === "Start Date:";
    const toIsEndDate = toPlaceholder === "End Date:";
    const fromBorderStyle = !toIsEndDate
        ? "1px solid #015294"
        : fromIsStartDate
            ? "1px solid #015294"
            : "1px solid #ccc";
    const toBorderStyle = toIsEndDate
        ? fromIsStartDate
            ? "1px solid #ccc"
            : "1px solid #015294"
        : "1px solid #ccc";
    // Determine button states
    const isClearDisabled = fromPlaceholder === "Start Date:";
    const isDateRange =
        defaultReportingPeriod.label.startsWith("Date range ") &&
        /\b\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}\b/.test(
            defaultReportingPeriod.label.substring(11)
        );
    const isConfirmDisabled =
        fromPlaceholder === "Start Date:" || toPlaceholder === "End Date:";
    const [disableConfirmButton, setdisableConfirmButton] = useState(false);
    useEffect(() => {
        setdisableConfirmButton(disableConfirm);
    }, [disableConfirm]);
    useEffect(() => {
        if (enableApply) {
            let isEnabled = false;

            if (reportViewType === paymentHistoryType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0;
            } else if (reportViewType === leaveType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    defaultPeriodScheduleTypeValue.length > 0 &&
                    defaultPeriodStatusValue.length > 0 &&
                    defaultProtectionStatusValue.length > 0 &&
                    (displayReportingGroupFilter ? selectedReportingGroup.length > 0 : true)
            } else if (reportViewType === disabilityType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0;
            } else if (reportViewType === absenceType) {
                isEnabled =
                    selectedCoverageType.length > 0 &&
                    selectedStatus.length > 0 &&
                    selectedPolicyDivision.length > 0 &&
                    defaultPeriodScheduleTypeValue.length > 0 &&
                    defaultPeriodStatusValue.length > 0 &&
                    defaultProtectionStatusValue.length > 0 &&
                    (displayReportingGroupFilter ? selectedReportingGroup.length > 0 : true)
            } else if (reportViewType === changesReportType) {
                isEnabled = selectedStatus.length > 0 &&
                    (displayReportingGroupFilter ? selectedReportingGroup.length > 0 : true)
            }
            setEnableApply(isEnabled);
        }
    }, [
        selectedCoverageType,
        selectedStatus,
        selectedReportingGroup,
        defaultPeriodScheduleTypeValue,
        defaultPeriodStatusValue,
        defaultProtectionStatusValue,
        selectedPolicyDivision,
        enableApply,
        reportViewType, // Ensure to include this dependency
    ]);

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1)
    //setMaxDate(maxDate.getDate() + 1);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const getReportingPeriondMessage = () => {
        if (reportViewType === disabilityType) {
            return "Reporting period includes any claims that had a status update within the selected date range.";
        } else if (reportViewType === leaveType) {
            return "Reporting period includes any leaves that had a status update within the selected date range.";
        } else if (reportViewType === paymentHistoryType) {
            return "Reporting period includes any claims that had a payment status date within the selected date range.";
        } else if (reportViewType === changesReportType) {
            return "This report includes data between the period selected and the current date."
        } else {
            return "Reporting period includes any claims or leaves that had a status update within the selected date range.";
        }
    };

    const formatedCoverageType = selectedCoverageType?.map(item => item.value).join(', ');

    const selectedReportingPeriod = defaultReportingPeriod.value === PREVIOUS_DAY ? ({ value: PREVIOUS_DAY, label: defaultReportingPeriod.label.split("-")[0] }) : defaultReportingPeriod;


    const CustomDropdownIndicator = (props) => {

        return (
            <components.DropdownIndicator {...props}>
                <DropDownIcon className={props?.selectProps?.menuIsOpen ? 'rotate-open' : 'rotate-closed'} />
            </components.DropdownIndicator>
        );
    };

    const ariaLabels = {
        monthPicker: 'Month picker dropdown',
        yearPicker: 'Year picker dropdown',
        prevButton: 'Navigate to the previous month',
        nextButton: 'Navigate to the next month',
    };
    const screenAccessMessage = (enableReset && enableApplyButton) ? "apply and reset buttons are available please reset or apply your changes by activates these buttons" : "" ||
        (enableReset && !enableApplyButton) && "Reset filters button is active"
    return (
        <div className="d-flex flex-direction">
            <div className="filter-header">
                <div className="organization-info">
                    <label className="pb-0 header" htmlFor="organization-name">Organization</label>
                    <p className="sub-text" id="organization-name">
                        {organizationName.length < 30 ? (
                            organizationName
                        ) : (
                            <div className="parameter-text d-block">
                                {organizationName}
                            </div>
                        )}
                    </p>
                </div>

                <div className="related-orgs-info">
                    <label className="pb-0 header" htmlFor="related-orgs">Related orgs</label>
                    <p className="sub-text" id="related-orgs">
                        {userSelectedOrganizationReports[reportId]?.related.length > 0 ? (
                            <div className="parameter-text d-block">
                                {relatedOrganizationNames}
                            </div>
                        ) : (
                            <span className="d-block">None</span>
                        )}
                    </p>
                </div>

                <div className="filter-actions">
                    <>
                        <div
                            className={`fs-16 text-semibold align-content-center ${!enableReset ? "disabled-text" : "hyper-links"}`}
                            role="button"
                            aria-disabled={!enableReset}
                            tabIndex={enableReset ? 0 : -1}
                            onClick={enableReset ? handleReset : undefined}
                            aria-live="polite"
                            onKeyDown={(e) => {
                                if (enableReset && (e.key === "Enter" || e.key === " ")) {
                                    handleReset();
                                    e.preventDefault()
                                }
                            }}
                        >
                            Reset filters
                            <i
                                className="fa-solid fa-arrow-rotate-left pl-8p"
                                aria-hidden="true"
                            ></i>
                        </div>

                    </>
                    <div
                        aria-live="polite"
                        style={{ position: "absolute", height: 0, width: 0, overflow: "hidden" }}
                    >
                        {screenAccessMessage}
                    </div>
                    <div className="fs-16">
                        <Button
                            text="Apply filters"
                            onClick={() => onApplyFilterClick(selectedValues)}
                            disabled={!enableApplyButton}
                            outline={true}
                            aria-label="Apply filters"
                        />
                    </div>
                </div>
            </div>

            <div className="row" style={{ rowGap: 16, marginTop: "10px" }}>
                <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                    <label className="header pb-0" htmlFor="reporting-period">Reporting period</label>
                    <div
                        style={{
                            position: "relative",
                            display: "inline-block",
                        }}
                    >
                        <i
                            className="fa-solid fa-circle-info"
                            style={{
                                color: "#FFFFFF",
                                paddingLeft: 4,
                                cursor: "pointer",
                            }}
                            tabIndex={0}
                            aria-label="More information about reporting period"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onFocus={handleMouseEnter}
                            onBlur={handleMouseLeave}
                        ></i>
                        {showTooltip && (
                            <div className="reporting-period-tooltip" role="tooltip">
                                {getReportingPeriondMessage()}
                                <div className="tooltipArrow"></div>
                            </div>
                        )}
                    </div>
                    <div className="reportfilter" tabIndex={0}>
                        <Select
                            className="reportTableFilter"
                            options={reportingPeriod}
                            {...commonSelectProps}
                            onChange={handleReportingPeriodChange}
                            value={selectedReportingPeriod}
                            components={{
                                Option: CustomOption,
                                DropdownIndicator: CustomDropdownIndicator,
                            }}
                            aria-label="Reporting Period"
                            id="reporting-period"
                        />
                        {showCalendar && (
                            <div
                                className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                                ref={daterangeref}
                            >
                                <div className="reportfilter" ref={popupRef}>
                                    <div className="section-card date-range-picker" role="dialog" aria-labelledby="date-range-picker">
                                        <div className="date-range-labels">
                                            <div
                                                className="from-label"
                                                style={{
                                                    border: fromBorderStyle,
                                                }}
                                                aria-label="Start date"
                                                tabIndex={0}
                                            >
                                                {fromPlaceholder}
                                            </div>

                                            {reportViewType !== changesReportType && (
                                                <div
                                                    className="to-label"
                                                    style={{
                                                        border: toBorderStyle,
                                                    }}
                                                    aria-label="End date"
                                                    tabIndex={0}
                                                >
                                                    {toPlaceholder}
                                                </div>
                                            )}
                                        </div>
                                        <DateRange
                                            onChange={handleDateRangeChange}
                                            moveRangeOnFirstSelection={false}
                                            ranges={customDateRange}
                                            months={reportViewType !== changesReportType ? 2 : 1}
                                            direction="horizontal"
                                            maxDate={maxDate}
                                            calendarFocus={reportViewType !== changesReportType ? "backwards" : "forwards"}
                                            retainEndDateOnFirstSelection={true}
                                            preventSnapRefocus={true}
                                            rangeColors={["#037CB7"]}
                                            disabledDates={[maxDate]}
                                            minDate={minimumStartDate}
                                            showDateDisplay={false}
                                            showMonthAndYearPickers={reportViewType === changesReportType ? false : true}
                                            showPreview={reportViewType !== changesReportType ? true : false}
                                            className={reportViewType === changesReportType ? "transparentMonthName" : ""}
                                            ariaLabels={ariaLabels}
                                        />

                                        <div className="button-container">
                                            <Button
                                                text="Clear"
                                                outline={true}
                                                onClick={handleClearDates}
                                                disabled={isClearDisabled}
                                                aria-label="Clear selected dates"
                                                style={{
                                                    minWidth: "80px",
                                                    fontSize: "16px",
                                                    color: "red",
                                                }}
                                                tabIndex={!showCalendar ? -1 : 0}
                                            />
                                            <Button
                                                text="Confirm"
                                                onClick={handleConfirmDates}
                                                disabled={
                                                    isConfirmDisabled ||
                                                    disableConfirmButton
                                                }
                                                aria-label="Confirm selected dates"
                                                style={{
                                                    minWidth: "100px",
                                                    fontSize: "16px",
                                                }}
                                                tabIndex={isConfirmDisabled || disableConfirmButton ? -1 : 0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {(reportViewType !== "Absence" && reportViewType !== changesReportType) && (
                    <MultiSelectComponent
                        headerLabel="Coverage type"
                        options={coverageTypeOptions}
                        selectedOptions={selectedCoverageType}
                        setSelectedOptions={setSelectedCoverageType}
                        setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        handleGtmTags={handleGtmTags}
                        gtmTagLabel="coverage type"
                    />
                )}
                {
                    reportViewType !== changesReportType &&
                    <MultiSelectComponent
                        headerLabel={reportViewType === paymentHistoryType ? "Payment status" : "Status"}
                        options={status}
                        selectedOptions={selectedStatus}
                        setSelectedOptions={setSelectedStatus}
                        setEnableReset={setEnableReset}
                        setEnableApplyButton={setEnableApplyButton}
                        handleGtmTags={handleGtmTags}
                        gtmTagLabel="status"
                    />
                }

                {(reportViewType === disabilityType ||
                    reportViewType === absenceType ||
                    reportViewType === paymentHistoryType) && (
                        <PoliciesAndDivisions
                            filteredPoliciesAndDivisions={filteredPoliciesAndDivisions}
                            selectedPolicyDivision={selectedPolicyDivision}
                            handleSelectAll={handleSelectAll}
                            handleUnselectAll={handleUnselectAll}
                            searchTerm={searchTerm}
                            handleSearchChange={handleSearchChange}
                            clearSearch={clearSearch}
                            handlePolicyCheckboxChange={handlePolicyCheckboxChange}
                            handleDivisionCheckboxChange={handleDivisionCheckboxChange}
                        />
                    )}

                {(reportViewType === leaveType ||
                    reportViewType === absenceType) && (
                        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print" style={{ position: "relative" }}>
                            <label className="header pb-0" htmlFor="include-historical-leave">
                                Include historical leave
                            </label>

                            <div
                                ref={historicalLeaveToggleRef}
                                className="d-flex justify-content-between cursor-pointer customFilterSection"
                                onClick={handleToggleHistoricalLeaveDropdown}
                                onKeyDown={handleHistoricalLeaveKeyDown}
                                role="combobox"
                                aria-expanded={isHistoricalLeaveDropdowOpen}
                                aria-controls="multi-select-dropdown"
                                aria-label="Include historical leave"
                                aria-describedby="include-historical-leave-description"
                                id="include-historical-leave"
                                tabIndex={0}
                                aria-activedescendant={`option-${focusedIndex}`}
                            >
                                <div className="customFilterDropDown" >{defaultIncludeHistoricalLeaveValue.label}</div>
                                <div className="d-flex" style={{ fontSize: 17, paddingLeft: 8, paddingRight: 8, gap: 8 }}>
                                    <i className={`fa-solid fa-angle-${isHistoricalLeaveDropdowOpen ? "up" : "down"}`}></i>
                                </div>

                            </div>
                            {isHistoricalLeaveDropdowOpen && (
                                <div className="custom-dropdown-container">
                                    <div
                                        ref={historicalLeaveDropdownRef}
                                        id="single-select-dropdown"
                                        className="section-card custom-section-dropdown"
                                        role="listbox"
                                        aria-labelledby="multi-select-label"
                                        aria-live="assertive"
                                        style={{ paddingTop: 0 }}

                                    >
                                        <div className="custom-scrollbar" style={{ maxHeight: 250, borderTop: 0 }}>
                                            {includeHistoricalLeave.length === 0 ? (
                                                <div className="d-flex justify-content-center my-8p text-center">No options</div>
                                            ) : (
                                                includeHistoricalLeave.map((option, index) => (
                                                    <div
                                                        key={option.value}
                                                        className={`select-option ${focusedIndex === index ? "focused" : ""}`}
                                                        role="option"
                                                        onClick={() => handleIncludeHistoricalValueChange(option)}
                                                        ref={(el) => (optionRefs.current[index] = el)}
                                                        tabIndex={-1}
                                                        aria-selected={focusedIndex === index ? "true" : "false"}
                                                    >
                                                        <label className="optionLabel" style={{ marginLeft: 8 }}>{option.label}</label>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                {(reportViewType === leaveType ||
                    reportViewType === changesReportType ||
                    reportViewType === absenceType) && displayReportingGroupFilter && (

                        <MultiSelectComponent
                            headerLabel="Reporting group"
                            options={reportingGroup}
                            selectedOptions={selectedReportingGroup}
                            setSelectedOptions={setSelectedReportingGroup}
                            setEnableReset={setEnableReset}
                            setEnableApplyButton={setEnableApplyButton}
                            anableSearch={true}
                        />
                    )}
                {(reportViewType === leaveType ||
                    reportViewType === absenceType) && (
                        <>
                            <MultiSelectComponent
                                headerLabel="Period schedule type"
                                options={periodScheduleType}
                                selectedOptions={defaultPeriodScheduleTypeValue}
                                setSelectedOptions={setPeriodScheduleTypeValue}
                                setEnableReset={setEnableReset}
                                setEnableApplyButton={setEnableApplyButton}
                            />
                            <MultiSelectComponent
                                headerLabel="Period status"
                                options={periodStatus}
                                selectedOptions={defaultPeriodStatusValue}
                                setSelectedOptions={setPeriodStatusValue}
                                setEnableReset={setEnableReset}
                                setEnableApplyButton={setEnableApplyButton}
                            />
                            <MultiSelectComponent
                                headerLabel="Protection status"
                                options={protectionStatus}
                                selectedOptions={defaultProtectionStatusValue}
                                setSelectedOptions={setProtectionStatusValue}
                                setEnableReset={setEnableReset}
                                setEnableApplyButton={setEnableApplyButton}
                            />
                        </>
                    )}

                {
                    reportViewType === "Absence" && (
                        <div className="col-lg-4 col-md-6 col-sm-4 col-6 col-print d-flex align-items-end coverageType-section">
                            <label className="header pb-0">Coverage types:</label>&nbsp;
                            <span className="coverageType-text d-block fs-sm">
                                {selectedCoverageType?.length > 0 ? formatedCoverageType : "None"}
                            </span>
                        </div>
                    )
                }

            </div>
        </div >
    );

};

export default ReportFilters;
