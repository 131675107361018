import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GTM from "../../GTM.js";

function HelpContent({ showHelpContent, closeHelp, content, handleBack }) {
    const [show, setShow] = useState(showHelpContent);
    const gtm = new GTM(null);
    useEffect(() => {
        setShow(showHelpContent);
    }, [showHelpContent]);

    const [activeAccordion, setActiveAccordion] = useState(null);


    const handleAccordionToggle = useCallback((faq, index) => {
        const isExpanding = index !== activeAccordion;
        setActiveAccordion((prevState) => (index === prevState ? null : index));
        if (isExpanding) {
            gtm.Event(
                "help section",
                "accordion",
                "expand",
                faq.Description,
                "help",
                content.Name,
                "help_item_click",
                ""
            );
        }
    }, []);

    const handleClose = () => {
        setShow(false);
        closeHelp();
    };

    // Function to render the description
    const renderDescription = (description) => {
        if (typeof description === "string") {

            return description?.split("\\n").map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        } else if (Array.isArray(description.Points)) {

            return (
                <ul className="faq-ul">
                    {description.Points.map((point, pointIndex) => (
                        <li key={pointIndex} className="faq-list">
                            {point}
                        </li>
                    ))}
                </ul>
            );
        }

        return null;
    };

    // Keydown event handler for scrolling
    const handleKeyDown = (event) => {
        const modalBody = document.querySelector('.modal-body');
        if (modalBody) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                modalBody.scrollBy({ top: 1, behavior: 'smooth' });
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                modalBody.scrollBy({ top: -1, behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show]);

    return (
        <Modal
            show={show}
            dialogClassName="help-section"
            size="xl"
            backdrop="true"
            onHide={handleClose}
            backdropClassName="helpContent-backdrop"
        >
            <div className="help-section-container">
                <Modal.Header style={{ justifyContent: "space-between" }}>
                    <div
                        className="help-buttons align-items-center"
                        style={{ fontWeight: 700 }}
                        onClick={handleBack}
                    >
                        <i className="fa-solid fa-angle-left"></i>
                        Back
                    </div>
                    <div
                        className="help-buttons align-items-center" style={{ paddingRight: 0 }}
                        onClick={handleClose}
                    >
                        <i className="fa-solid fa-xmark"></i>
                        Close
                    </div>
                </Modal.Header>
                <Modal.Body
                    style={{
                        padding: 0,
                        maxHeight: "calc(100vh - 140px)",
                        overflowY: "auto",
                        height: 1000,
                    }}
                >
                    <div className="pr-32p">
                        <div className="header pt-20p">{content.Name}</div>

                        <div style={{ width: 604 }}>
                            {content.Content.map((faq, index) => (
                                <div className="accordion" key={index}>
                                    <div
                                        className="d-flex px-20p faq-subtext cursor-pointer"
                                        onClick={() => handleAccordionToggle(faq, index)}
                                    >
                                        <h2 className="section-heading mb-0p">
                                            <div className="faq-header">
                                                {faq.Header}
                                            </div>
                                        </h2>
                                        <div>
                                            <i
                                                className={`fa-solid fa-${activeAccordion === index
                                                    ? "minus"
                                                    : "plus"
                                                    } accordion-icon ml-8p`}
                                                tabIndex="0"
                                            ></i>
                                        </div>
                                    </div>
                                    {activeAccordion === index && (
                                        <div className="faq-subtext-collapse accordion-collapse show">
                                            <div className="faq-card card-body">
                                                <div className="text-align-justify">
                                                    {renderDescription(faq.Description)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <hr className="mb-20p mx-20p faq-divider" />
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default HelpContent;
