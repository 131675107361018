import React from "react";

function Button({ onClick, text, icon, outline, type, ...rest }) {

    return (
        <>
            <button
                type={type ? type : "button"}
                className={outline === true ? "btn-outline" : "btn-primary"}
                onClick={onClick}
                aria-live="polite"
                {...rest}
            >
                {rest.pageType === "reportSaving" ? (
                    <>
                        {text}
                        {icon && <i className={"fa-solid pl-10p " + icon}></i>}
                    </>
                ) : (
                    <>
                        {icon && <i className={"fa-solid pr-10p " + icon}></i>}

                        {text}
                    </>
                )}
            </button>
            <div
                aria-live="assertive"
                style={{ position: "absolute", height: 0, width: 0, overflow: "hidden" }}
            >
                {!rest.disabled && `${text} button is active`}
            </div>
        </>
    );
}

export default Button;
