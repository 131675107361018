import React, { useState, useEffect } from "react";
import { listenerMiddleware } from "../store";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import { useLazyGetOrganizationAccessQuery } from "../slices/employeesApiSlice";
import { useLazyGetSessionInfoQuery } from "../slices/sessionSlice";
import Button from "./ui/Button";
import { useTimer } from "react-timer-hook";
import { dateToLocalTimestamp } from "../utils/helpers";
import moment from "moment";

function SessionTimeout({ showModal = false }) {
    const dispatch = useDispatch();
    const { organizationsAccess } = useSelector((state) => state.organizations);
    const [getOrganizationAccess, { data = null, error, isFetching }] =
        useLazyGetOrganizationAccessQuery();
    const [getSessionInfo] = useLazyGetSessionInfoQuery();
    const [displayPopup, setDisplayPopup] = useState(false);
    const [show, setShow] = useState(showModal);
    const [countdownComplete, setCountdownComplete] = useState(false);
    const {
        totalSeconds,
        start = handleStart,
        pause,
        restart,
    } = useTimer({
        expiryTimestamp: new Date(),
        autoStart: false,
        onExpire: () => {
            setCountdownComplete(true);
        },
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onIdle = () => {
        dispatch(logout());
        handleClose();
    };

    const handleStart = async (user) => {
        if (user) {
            if (user?.isUserInternal) {
                const minutes =
                    process.env.REACT_APP_SESSION_LIFETIME_IN_SECONDS / 60;
                const expirationTime = new Date(
                    new Date().getTime() + minutes * 60000
                );

                restart(expirationTime);
            } else {
                sessionInfoCall();
            }
        }
    };

    const sessionInfoCall = async () => {
        const sessionData = await getSessionInfo().unwrap();

        const sessionTimeout = moment(
            dateToLocalTimestamp(sessionData.maxIdleExpirationTime)
        ).local()._d;
        restart(sessionTimeout);
    };
    useEffect(() => {
        if (
            totalSeconds > 0 &&
            totalSeconds <=
                process.env.REACT_APP_SESSION_COUNTDOWN_TIMER_IN_SECONDS &&
            !displayPopup
        ) {
            if (!show) handleShow();
            setDisplayPopup(true);
        }
    }, [totalSeconds]);

    useEffect(() => {
        if (error && error.status === 401) {
            dispatch(logout());
            return;
        }

        if (data && !isFetching) {
            console.log("API call for org access ");
        }
    }, [data, isFetching, error]);

    useEffect(() => {
        listenerMiddleware.startListening({
            predicate: (action, currentState) => {
                if (String(action.type).includes("executeQuery/fulfilled")) {
                    if (!action.type.includes("sessionSlice/executeQuery")) {
                        handleStart(currentState?.auth?.user);
                    }
                }
            },
            effect: async () => {
                // Run whatever additional side-effect-y logic you want here
                console.log("listenerMiddleware effect hit");
            },
        });
    }, []);

    const continueSession = () => {
        pause();
        const orgIds = [];
        for (const prop in organizationsAccess) {
            orgIds.push(prop);
        }
        getOrganizationAccess({
            organizationIds: orgIds.join(","),
        });
        handleClose();
        setDisplayPopup(false);
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
                size={"sm"}
            >
                <Modal.Header
                    style={{
                        background: "#E6EFF5",
                        padding: 16,
                    }}
                >
                    <span className="fs-sm text-bold">Attention</span>
                    <i
                        className="fa-solid fa-xmark cursor-pointer"
                        onClick={countdownComplete ? onIdle : handleClose}
                        role="button"
                        aria-label="Close"
                        type="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              if(countdownComplete){
                                onIdle()
                              }else{
                                handleClose()
                              }
                            }
                          }}
                    ></i>
                </Modal.Header>

                <Modal.Body
                    style={{
                        padding: 32,
                    }}
                >
                    {countdownComplete && (
                        <>
                            <div className="text-semibold">
                                <p>
                                    You have been signed out due to inactivity.
                                </p>
                            </div>
                            <div className="col-12">
                                <Button
                                    text="Login"
                                    onClick={onIdle}
                                    data-testid="logout"
                                    tabIndex={0}
                                />
                            </div>
                        </>
                    )}

                    {!countdownComplete && (
                        <>
                            <div className="mb-16p">
                                <span className="mb-16p text-semibold">
                                    Your session is about to expire. <br /> You
                                    will be logged out in{" "}
                                    {Math.floor(totalSeconds / 60)} min{" "}
                                    {totalSeconds % 60} sec.
                                </span>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-6">
                                    <Button
                                        text="Log out"
                                        onClick={onIdle}
                                        data-testid="logout"
                                        tabIndex={0}
                                    />
                                </div>

                                <div className="col-6">
                                    <span
                                        className="text-primary-main cursor-pointer "
                                        onClick={continueSession}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" || e.key === " ") {
                                              e.preventDefault();
                                              continueSession();
                                            }
                                          }}
                                        data-testid="continue"
                                        style={{
                                            fontWeight: "bold",
                                            marginLeft: 30,
                                        }}
                                        tabIndex={0}
                                        role="button"
                                    >
                                        Continue
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default SessionTimeout;
