import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";
import { getButtonDisabledStatus } from "../../utils/helpers";

export default function ReportSavingPage({
    onShow,
    onSave,
    onDontSave,
    notification,
    reportViewType,
}) {
    const firstInteractiveElementRef = useRef(null);
    const modalContentRef = useRef(null); // Attach ref to a DOM element wrapping the modal content
    const gtm = new GTM(null);

    const [viewNameChange, setViewNameChange] = useState(false);
    const [descriptionChange, setDescriptionChange] = useState(false);
    const [viewNameValue, setViewNameValue] = useState();
    const [descriptionValue, setDescriptionValue] = useState();

    const handleGtmTags = (label, attribute3) => {
        gtm.Event(
            "manage my reports",
            "button",
            "click",
            label,
            "report detail",
            reportViewType.toLowerCase(),
            attribute3,
            ""
        );
    };

    const sendSaveEvent = () => {
        onSave(viewNameValue, descriptionValue);
        notification("NEW");
        handleGtmTags("save", "save_report");
    };

    const sendDontSaveEvent = () => {
        setViewNameChange(false);
        setDescriptionChange(false);
        onDontSave();
        handleGtmTags("dont save", "field_interaction");
    };

    const handleViewNameChange = ({ target: input }) => {
        if (input.value.length && input.value.trim()) {
            setViewNameChange(true);
        } else {
            setViewNameChange(false);
        }
        setViewNameValue(input.value);
    };

    const handleDescriptionValueChange = ({ target: input }) => {
        if (input.value.length && input.value.trim()) {
            setDescriptionChange(true);
        } else {
            setDescriptionChange(false);
        }
        setDescriptionValue(input.value);
    };

    useEffect(() => {
        if (onShow && firstInteractiveElementRef.current) {
            firstInteractiveElementRef.current.focus();
        }
    }, [onShow]);

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            sendDontSaveEvent(); // Close the dialog on Escape key
        } else if (event.key === "Tab") {

            const focusableElements = Array.from(modalContentRef.current.querySelectorAll(
                "button, input, i"
            )).filter((el) => !el.disabled); // Exclude disabled elements
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];
            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        }
    };

    return (
        <Modal
            size="sm"
            show={onShow}
            backdrop="static"
            keyboard={false}
            centered
            className="preference"
            dialogClassName="custom-modal-size"
            id="save popup Dialog"
            aria-label="save popup"
            onKeyDown={(e) => handleKeyDown(e)}
        >
            <div ref={modalContentRef}>
                {/* Attach ref to this wrapping div */}
                <Modal.Header>
                    <SectionHeading heading="Save report" dialogId="dialogTitle" ref={firstInteractiveElementRef} />
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer close-button"
                        onClick={sendDontSaveEvent}
                        aria-label="Close"
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                e.preventDefault();
                                sendDontSaveEvent();
                            }
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label htmlFor="reportName">Report name</label>
                        </div>
                        <input
                            type="text"
                            id="reportName"
                            className="form-control"
                            placeholder="Add a name"
                            onChange={handleViewNameChange}
                            aria-required="true"
                            autoComplete="off"
                            aria-describedby="reportNameDesc"
                        // Focus this element on dialog open
                        />
                    </div>
                    <div>
                        <div>
                            <label
                                htmlFor="description"
                                style={{
                                    paddingTop: 12,
                                }}
                            >
                                Description
                            </label>
                        </div>
                        <input
                            type="text"
                            id="description"
                            className="form-control"
                            placeholder="Add a description"
                            onChange={handleDescriptionValueChange}
                            aria-required="true"
                            aria-describedby="descriptionDesc"
                            autoComplete="off"
                        />
                    </div>
                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Don't save"
                                text="Don't save"
                                outline={true}
                                onClick={sendDontSaveEvent}
                                aria-label="Don't save the report"
                                role="button"

                            />
                            <Button
                                text="Save"
                                onClick={sendSaveEvent}
                                disabled={getButtonDisabledStatus(viewNameChange, viewNameValue, descriptionChange, descriptionValue)}
                                aria-label="Save the report"
                                role="button"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}
