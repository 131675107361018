import React from "react";
import Table from "../../components/table/Table";

const DefinitionAndClaimComponent = ({ content }) => {

    const ClaimStatusColumns = React.useMemo(
        () => [
            {
                Header: "Claim status reason",
                columns: [
                    {
                        Header: "Claim Status",
                        accessor: "Status",
                        disableSortBy: true,
                        width: 130,
                    },
                    {
                        Header: "Status Reason",
                        accessor: "Status Reason",
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        Header: "Definition",
                        accessor: "Definition",
                        disableSortBy: true,
                        width: 280,
                    },
                ],
            },
        ],
        []
    );

    const DiagnosticColumns = React.useMemo(
        () => [
            {
                Header: "Diagnostic Categories",
                columns: [
                    {
                        Header: "Code",
                        accessor: "Code",
                        disableSortBy: true,
                        width: 130,
                    },
                    {
                        Header: "Definition",
                        accessor: "Definition",
                        disableSortBy: true,
                        width: 190,
                    },
                    {
                        Header: "Example",
                        accessor: "Example",
                        disableSortBy: true,
                        width: 255,
                    },
                ],
            },
        ],
        []
    );

    const tableColumn = content.Name === "Diagnostic Categories" ? DiagnosticColumns : ClaimStatusColumns

    return (
        <>
            {
                content?.Content.length > 0 ? <Table
                    columns={tableColumn}
                    data={content?.Content}
                    responsiveConfig={{
                        type: "COLUMN",
                        striped: false,
                        screen: "md",
                    }}
                    pagination={false}
                    className="reference-table"
                /> :
                    <div className="d-flex align-items-center justify-content-center" style={{ margin: "0px", height: "50px" }}>No Data</div>
            }

        </>
    );
};

export default DefinitionAndClaimComponent;
