import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SearchOrganization from "../views/searchview/SearchOrganization";
import Redirect from "../views/Redirect";
import EmployeeSearch from "../views/searchview/EmployeeSearch";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import EmployeeClaimLeave from "../views/claimleaveview/EmployeeClaimLeave";
import ReportTypeSelection from "../views/reports/ReportTypeSelection";
import ReportSelection from "../views/reports/ReportSelection";
import Report from "../views/reports/Report";
import Preferences from "../views/preferences/Preferences";
import Login from "../Login";
import LandingPage from "../components/LandingPage";
import ViewSectionPage from "../views/reports/ViewSectionPage";
import ReportViewPage from "../views/reports/ReportViewPage";
import { EventProvider } from "../EventContext";
import ManageMyView from "../views/reports/ManageMyView";
import { HelmetProvider, Helmet } from "react-helmet-async";

function AppRoutes() {
    const { user } = useSelector((state) => state.auth);
    const { totalParentOrganizations } = useSelector(
        (state) => state.organizations
    );

    // to find previews page URL
    const useFindPath = () => {
        const location = useLocation();
        const [currentPath, setCurrentPath] = useState([]);
        useEffect(() => {
            setCurrentPath([...currentPath, window.location.pathname]);
        }, [location]);

        return currentPath;
    };

    const previousUrl = useFindPath();

    //Increase iFrame width on report screen
    const matchUrl = /reports\/[a-z]+\/[a-z]+\/\d/gm;
    const url = window.location.pathname;

    let urlDisplay = url.match(matchUrl);

    const className = urlDisplay === null ? "container " : "";

    const [showInformationBanner, setShowInformationBanner] = useState(true);

    const hideInformationBanner = () => {
        setShowInformationBanner(false);
    };

    const [isPreferenceSaved, setIsPreferenceSaved] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const handleSavedPreference = () => {
        setShowNotification(true);
    };
    const handleClosePreference = () => {
        setShowNotification(false);
    };
    useEffect(() => {
        if (isPreferenceSaved) {
            setShowNotification(true);

            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return () => {
                if (timer) clearTimeout(timer);
            };
        }
    }, [isPreferenceSaved]);

    return (
        <HelmetProvider>
            <div id="container_main">
                <EventProvider>
                    <Header
                        showInformationBanner={showInformationBanner}
                        hideInformationBanner={hideInformationBanner}
                        handleSavedPreference={handleSavedPreference}
                        showNotification={showNotification}
                        handleClosePreference={handleClosePreference}
                    />

                    <main
                        style={{
                            paddingBottom: urlDisplay === null ? "" : "0px",
                        }}
                    >
                        <div className={"container-content " + className}>
                            <div className="row">
                                <div
                                    className="col-md-12"
                                    style={{ padding: 0 }}
                                >
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <>
                                                    <Helmet>
                                                        <title>Redirect</title>
                                                    </Helmet>
                                                    <Redirect
                                                        previousUrl={
                                                            previousUrl
                                                        }
                                                    />
                                                </>
                                            }
                                        />
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />

                                        {user && (
                                            <>
                                                <Route
                                                    path="/organization/:orgId/employees/:id"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Claim & Leave
                                                                </title>
                                                            </Helmet>
                                                            <EmployeeClaimLeave
                                                                previousUrl={
                                                                    previousUrl
                                                                }
                                                                showInformationBanner={
                                                                    showInformationBanner
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />

                                                <Route
                                                    path="/home"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Unum Insights
                                                                </title>
                                                            </Helmet>
                                                            <LandingPage />
                                                        </>
                                                    }
                                                />

                                                <Route
                                                    path="/organization/:id/employees/search"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Search
                                                                </title>
                                                            </Helmet>
                                                            <EmployeeSearch
                                                                previousUrl={
                                                                    previousUrl
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />

                                                <Route
                                                    path="/reports"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Reports
                                                                </title>
                                                            </Helmet>
                                                            <ReportTypeSelection />
                                                        </>
                                                    }
                                                />
                                                <Route
                                                    path="/reportingcapabilities"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Reports
                                                                </title>
                                                            </Helmet>
                                                            <ViewSectionPage
                                                                previousUrl={
                                                                    previousUrl
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />
                                                <Route
                                                    path="/reportingcapabilities/:viewtype"
                                                    element={
                                                        <ReportViewPage
                                                            previousUrl={
                                                                previousUrl
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/reportingcapabilities/:viewType/manageviews"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Manage Reports
                                                                </title>
                                                            </Helmet>
                                                            <ManageMyView />
                                                        </>
                                                    }
                                                />

                                                <>
                                                    {" "}
                                                    <Route
                                                        path="/reports/:type"
                                                        element={
                                                            <>
                                                                <Helmet>
                                                                    <title>
                                                                        Reports
                                                                    </title>
                                                                </Helmet>
                                                                <ReportSelection />
                                                            </>
                                                        }
                                                    />
                                                    <Route
                                                        path="/reports/:type/:group/:reportId"
                                                        element={<Report />}
                                                    />
                                                </>

                                                <Route
                                                    path="/reportingcapabilities/manageviews"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Manage my reports
                                                                </title>
                                                            </Helmet>
                                                            <ManageMyView />
                                                        </>
                                                    }
                                                />
                                                <Route
                                                    path="/preferences"
                                                    element={
                                                        <>
                                                            <Helmet>
                                                                <title>
                                                                    Preferences
                                                                </title>
                                                            </Helmet>
                                                            <Preferences
                                                                setIsPreferenceSaved={
                                                                    setIsPreferenceSaved
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />

                                                {totalParentOrganizations >
                                                    1 && (
                                                        <Route
                                                            path="/search"
                                                            element={
                                                                <>
                                                                    <Helmet>
                                                                        <title>
                                                                            Search
                                                                        </title>
                                                                    </Helmet>
                                                                    <SearchOrganization
                                                                        previousUrl={
                                                                            previousUrl
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                    )}
                                            </>
                                        )}

                                        <Route
                                            path="*"
                                            element={
                                                <Navigate replace to="/" />
                                            }
                                        />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </main>

                    <Footer />
                </EventProvider>
            </div>
        </HelmetProvider>
    );
}

export default AppRoutes;
